import { newDate } from 'date-fns-jalali';
import { useMutation } from 'react-query';
import { authPostKeygen as faAuthPostKeygen } from 'src/core/query/fa/authClient';
import { useAllAuthInfo } from '../../hooks/useAllAuthInfo';

export const usePersonalInfo = () => {
  const { refetch: refetchAllAuthInfo } = useAllAuthInfo();

  const { mutateAsync: instantSubmitPersonalInfo, isLoading: instantIsLoadingPersonalInfoSubmit } =
    useMutation(faAuthPostKeygen(`/api/v1/auth/user/investor/auth/instant/personalinfo`));

  const {
    mutateAsync: notInstantSubmitPersonalInfo,
    isLoading: notInstantIsLoadingPersonalInfoSubmit,
  } = useMutation(faAuthPostKeygen(`/api/v1/auth/user/investor/auth/notinstant/personalinfo`));

  const setPersonalInfoDefaultValues = () => {
    return refetchAllAuthInfo().then(({ data }) => {
      let date: Date | undefined;
      // date is a string in 'yyyy/mm/dd' format
      if (data?.data?.birth_date) {
        const [year, month, day] = data.data.birth_date.split('/').map((date) => Number(date));
        date = newDate(year, month - 1, day);
      }
      return {
        first_name: data?.data?.first_name ?? '',
        birth_date: date as any,
        gender: data?.data?.gender ?? 'male',
        last_name: data?.data?.last_name ?? '',
        email: data?.data?.email ?? '',
        mobile: data?.data?.mobile ?? '',
        national_id: data?.data?.national_id ?? '',
      };
    });
  };

  const isLoadingPersonalInfoSubmit =
    instantIsLoadingPersonalInfoSubmit || notInstantIsLoadingPersonalInfoSubmit;

  return {
    setPersonalInfoDefaultValues,
    instantSubmitPersonalInfo,
    notInstantSubmitPersonalInfo,
    isLoadingPersonalInfoSubmit,
  };
};
