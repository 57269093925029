import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useMedia } from 'src/shared/hooks';
import { Close } from 'src/shared/icons/Close';
import { components } from 'src/types/fa/auth';
import { makeStyles } from 'tss-react/mui';
import { useAuthenticationContext } from '../../../authenticationTypeContext';
import { useConfirm } from 'src/shared/contexts/confirm/confirm.context';

type PropsType = {
  disabled: boolean;
  handleDelete: () => void;
  cardData: Exclude<
    components['schemas']['datastruct.GetBankAccountOutputs']['data'],
    undefined
  >['accounts'][number];
  isLoadingDelete: boolean;
};
export const BankCard = (props: PropsType) => {
  const { classes } = useStyles();
  const isDownMd = useMedia('md');
  const confirm = useConfirm();
  const { authenticationType } = useAuthenticationContext();

  const handleDelete = () => {
    confirm().then(props.handleDelete);
  };

  return (
    <div className={classes.card}>
      <div className={classes.row}>
        <Typography variant="body2" color={'neutral.dark'}>
          {props.cardData.bank_name}
        </Typography>
        {!isDownMd && !props.disabled && (
          <LoadingButton
            color="error"
            size="small"
            variant="outlined"
            loading={props.isLoadingDelete}
            onClick={handleDelete}
            className={classes.delete}
          >
            <Close />
            {t`Delete`}
          </LoadingButton>
        )}
      </div>
      <Typography variant="body2" color={'neutral.dark'} className="font-en">
        {props.cardData.card_number}
      </Typography>
      <Typography variant="body2" noWrap color={'neutral.dark'} className="font-en">
        {authenticationType === 'instant'
          ? props.cardData.iban_number
          : props.cardData.account_number}
      </Typography>
      {isDownMd && !props.disabled && (
        <LoadingButton
          color="error"
          size="small"
          variant="outlined"
          loading={props.isLoadingDelete}
          onClick={handleDelete}
          className={classes.delete}
        >
          <Close />
          {t`Delete`}
        </LoadingButton>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 10,
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.neutral.lightExtraBlue,
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    justifyContent: 'space-between',
  },
  delete: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.error.main,
  },
}));
