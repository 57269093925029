import { t } from '@lingui/macro';
import {
  Divider,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  InputProps,
  ListItemIcon,
  ListItemIconProps,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps,
  Typography,
  buttonBaseClasses,
  formLabelClasses,
  menuItemClasses,
} from '@mui/material';
import { ArrowDown } from 'src/shared/icons/ArrowDown';
import { Check } from 'src/shared/icons/Check';
import type { ValueLabelList } from 'src/shared/types';
import { makeStyles } from 'tss-react/mui';

export interface OwnProps<Value extends string | number, Label extends React.ReactNode>
  extends Pick<InputProps, 'fullWidth' | 'disabled' | 'size' | 'startAdornment'> {
  label?: string;
  helperText?: string;
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  formHelperTextProps?: FormHelperTextProps;
  menuItemProps?: MenuItemProps;
  listItemIconProps?: ListItemIconProps;
  options: ValueLabelList<Value, Label>;
  selectIndicator?: React.ReactElement;
  showAll?: boolean;
}

export const Select = <Value extends string | number, Label extends React.ReactNode>(
  props: SelectProps<Value> & OwnProps<Value, Label>,
) => {
  const {
    label,
    disabled,
    helperText,
    error,
    formHelperTextProps,
    fullWidth,
    formLabelProps,
    formControlProps,
    menuItemProps,
    options: optionsProp,
    selectIndicator = <Check />,
    listItemIconProps,
    showAll,
    IconComponent = ArrowDown,
    color = 'secondary',
    required,
    placeholder,
    ...rest
  } = props;
  const { classes } = useStyles();

  const options = showAll ? [{ value: '', label: t`All` }, ...optionsProp] : optionsProp;

  return (
    <FormControl
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      {...formControlProps}
    >
      {label && (
        <FormLabel classes={{ root: classes.formLabel }} sx={{ mb: 1, ...formLabelProps?.sx }}>
          {label}
        </FormLabel>
      )}
      <MuiSelect
        IconComponent={IconComponent}
        color={color}
        error={error}
        renderValue={(value) => {
          return (
            options.find((option) => option.value === value)?.label || (
              <Typography className={classes.placeholder}>{placeholder}</Typography>
            )
          );
        }}
        value={props.value}
        style={{ color: props.value === '' ? '#a2a2a2' : undefined }}
        displayEmpty={!!placeholder}
        {...rest}
      >
        {options.map(({ label, value }, index) => [
          <MenuItem
            classes={{ root: classes.menuItem }}
            {...menuItemProps}
            value={value}
            key={index}
          >
            {typeof label === 'string' || typeof label === 'number' ? (
              <Typography>{label}</Typography>
            ) : (
              label
            )}
            {selectIndicator && value === rest.value && (
              <ListItemIcon classes={{ root: classes.listItemIcon }} {...listItemIconProps}>
                {selectIndicator}
              </ListItemIcon>
            )}
          </MenuItem>,
          index !== options.length - 1 && <Divider classes={{ root: classes.divider }} />,
        ])}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error} {...formHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
const useStyles = makeStyles()((theme) => ({
  formLabel: {
    color: theme.palette.common.black,
    [`&.${formLabelClasses.focused}`]: {
      color: theme.palette.common.black,
    },
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.secondary.light,
  },
  menuItem: {
    [`&.${buttonBaseClasses.root}.${menuItemClasses.selected}`]: {
      background: 'transparent',
      color: theme.palette.secondary.light,
    },
    justifyContent: 'space-between',
  },
  divider: {
    marginLeft: 8,
    marginRight: 8,
    borderColor: theme.palette.neutral.lightBlue,
  },
  placeholder: {
    color: theme.palette.neutral.lightGrey,
  },
}));
