export const lsKeys = {
  en: {
    auth: {},
  },
  fa: {
    pay: {
      withdraw: 'pay-withdraw-sms-temp-code',
    },
    auth: {
      instantSubmit: 'personal-info-sms-temp-code',
      notInstantPhone: 'contact_info_mobile_temp_code',
      notInstantEmail: 'contact_info_email_temp_code',
    },
  },
};
