import { UseFormSetError } from 'react-hook-form';
import { useQuery } from 'react-query';
import { authQueryKeygen } from 'src/core/query/fa/authClient';
import { setCaptionError } from 'src/shared/utils/error-utils';

type PropsType = {
  postalCode: string;
  onAddressSuccessfullyFetched: (address: string) => void;
  setError: UseFormSetError<any>;
};

export const usePostalCodeAddress = (props: PropsType) => {
  //@ts-ignore
  return useQuery({
    ...authQueryKeygen('/api/v1/auth/check-postalcode', {
      params: {
        query: {
          postalcode: props.postalCode,
        },
      },
    }),
    enabled: false,
    onSuccess: (data) => {
      if (data.data?.address && data.data?.address.length > 0) {
        props.onAddressSuccessfullyFetched(data.data?.address);
      } else {
        console.log('in else');
        throw new Error('address received from  postal code api is empty');
      }
    },
    onError: (e) => {
      setCaptionError((e as any).data, props.setError);
    },
  });
};
