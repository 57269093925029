import { t } from '@lingui/macro';
import { UseFormWatch } from 'react-hook-form';
import { components } from 'src/types/fa/auth';
import { PersonalInformationSchemaType } from '../../schema';

export type PersonalInfoFieldType = {
  label: string;
  placeholder: string;
  type: 'text' | 'select' | 'date';
  name: NameType | 'email' | 'mobile';
  disable?: boolean;
  confirmed?: boolean;
};

type NameType = Exclude<
  keyof components['schemas']['datastruct.NotInstantPersonalInfoAuthInputs'],
  'issue_town' | 'father_name' | 'mobile'
>;

type PropsType = {
  status?: components['schemas']['datastruct.InvestorAuthStatusOutputs']['data'];
  allAuthInfo?: components['schemas']['datastruct.GetInvestorAllInfoOutputs']['data'];
  watch: UseFormWatch<PersonalInformationSchemaType>;
};

export const dataInputs = (props: PropsType): PersonalInfoFieldType[] => [
  {
    label: t`Email`,
    placeholder: t`Enter your email`,
    type: 'text',
    name: 'email',
    disable:
      props.status?.identifier === 'email_verified' || props.status?.identifier === 'full_verified',

    confirmed:
      !!props.allAuthInfo?.email &&
      props.allAuthInfo?.email === props.watch('email') &&
      (props.status?.identifier === 'email_verified' ||
        props.status?.identifier === 'full_verified'),
  },
  {
    label: t`Phone number`,
    placeholder: t`Enter your phone number`,
    type: 'text',
    name: 'mobile',
    disable:
      (props.status?.auth_type === 'instant' &&
        props.status?.account !== 'identity_docs_completed') ||
      props.status?.account === 'active',
    confirmed:
      !!props.allAuthInfo?.mobile &&
      props.allAuthInfo?.mobile === props.watch('mobile') &&
      (props.status?.identifier === 'phone_verified' ||
        props.status?.identifier === 'full_verified'),
  },
  {
    label: t`Name`,
    placeholder: t`Enter your name`,
    type: 'text',
    name: 'first_name',
  },
  {
    label: t`Last name`,
    placeholder: t`Enter your last name`,
    type: 'text',
    name: 'last_name',
  },
  {
    label: t`National Code`,
    placeholder: t`Enter your national code`,
    type: 'text',
    name: 'national_id',
  },
  {
    label: t`Gender`,
    placeholder: t`Select the desired option`,
    type: 'select',
    name: 'gender',
  },
  {
    label: t`Date of birth`,
    placeholder: t`Select the desired date`,
    type: 'date',
    name: 'birth_date',
  },
];
