import { t } from '@lingui/macro';
import { numRegex } from 'src/shared/regex/textRegex';
import { customValidations } from 'src/shared/utils/form-validations';
import * as yup from 'yup';

export const verifyOTPCodeSchema: yup.ObjectSchema<VerifyOTPCodeSchemaType> = yup.object({
  code: customValidations
    .stringMatchesRegexRequired(numRegex)
    .length(5, t`Enter exactly ${5} digits`),
});

export type VerifyOTPCodeSchemaType = {
  code: string;
};
