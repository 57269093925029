import { makeStyles } from 'tss-react/mui';
import { BankInformationStage } from '../stages/bankInfoStage/BankInformationStage';
import { ContactInfoStage } from '../stages/contactInfoStage/ContactInfoStage';
import { PersonalInfoStage } from '../stages/personalInfo/PersonalInfoStage';
import { UploadDocumentsStage } from '../stages/uploadDocuments/UploadDocumentsStage';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  isActive: boolean;
};
const stages = [UploadDocumentsStage, PersonalInfoStage, ContactInfoStage, BankInformationStage];

export const AuthenticationForms = (props: PropsType) => {
  const { classes } = useStyles();
  const Stage = stages[props.formStep];
  return (
    <div className={classes.container}>
      <Stage formStep={props.formStep} setFormStep={props.setFormStep} disabled={props.isActive} />
    </div>
  );
};
const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));
