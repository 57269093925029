import { Dialog } from '@mui/material';
import { notCompletedAuthModalStore } from 'src/core/stores/notCompletedAuthModalStore';
import { useStore } from 'zustand';
import { NotCompletedAuthCard } from './NotCompletedAuthCard';

export const NotCompletedAuthModal = () => {
  const state = useStore(notCompletedAuthModalStore);

  return (
    <Dialog open={state.open} onClose={state.onClose} PaperProps={{ elevation: 1 }}>
      <NotCompletedAuthCard />
    </Dialog>
  );
};
