import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, Typography, alpha } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { authPostKeygen, authQueryKeygen } from 'src/core/query/fa/authClient';
import { AutoComplete } from 'src/shared/components/AutoComplete';
import { Close } from 'src/shared/icons/Close';
import { setCaptionError } from 'src/shared/utils/error-utils';
import { makeStyles } from 'tss-react/mui';
import { FaNotInstantVerifyBankCodeSchemaType, faNotInstantVerifyBankCodeSchema } from './schema';
import FormTextField from '../form-fields/form-text-field';

interface PropsType {
  open: boolean;
  onClose: () => void;
}

export const NotInstantBankModal = (props: PropsType) => {
  const { open, onClose } = props;
  const { classes } = useStyles();
  const { mutateAsync: mutateAsyncBank, isLoading: isBankLoading } = useMutation(
    authPostKeygen('/api/v1/auth/user/investor/bank/notinstant'),
  );

  const { data: bankListData } = useQuery({
    ...authQueryKeygen('/api/v1/auth/banks-list', {
      params: { header: {} },
    }),
  });

  const methods = useForm<FaNotInstantVerifyBankCodeSchemaType>({
    defaultValues: { code: '' },
    mode: 'onBlur',
    resolver: zodResolver(faNotInstantVerifyBankCodeSchema()),
  });

  const {
    control,
    formState: { errors, isValid },
    setError,
    watch,
    reset,
  } = methods;

  const sendCardNumber = (accountNumber: string, cardNumber: string, bankId: number) => () => {
    mutateAsyncBank({
      body: {
        bank_id: bankId,
        account_number: accountNumber,
        card_number: cardNumber,
      },
      params: {},
    }).then(({ response: resp, data, error }) => {
      if (resp.status === 400) {
        setCaptionError(
          {
            code: (error as any).data.account_number || (error as any).data.bank,
            card_number: (error as any).data.card_number,
            bank_id: (error as any).data.bank_id,
          },
          setError,
        );
      } else if (data) {
        reset({
          bank_id: undefined as any,
          card_number: '',
          code: '',
        });
        props.onClose();
      }
    });
  };

  const labelValueOptions = bankListData?.data?.banks
    ? bankListData.data?.banks.map((obj) => ({
        label: obj.name,
        value: obj.id,
      }))
    : [];

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <FormProvider {...methods}>
        <div className={classes.title}>
          <Typography variant="h4">{t`Add bank account`}</Typography>
        </div>
        <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
        <div className={classes.form}>
          <Typography variant="caption2" color={'neutral.darkGrey'}>
            {t`Canadian banking account number consists of: Institution Number( 4 digits long) + Transit Number( 5 digits long) + Account Number( 7-12 digits long)`}
          </Typography>
          <FormTextField
            required
            name="code"
            convertFaNumbersToEn
            label={t`Account number`}
            placeholder={t`Enter the account number`}
            inputProps={{ maxLength: 20 }}
          />
          <FormTextField
            required
            type="tel"
            name="card_number"
            label={t`Card number`}
            placeholder={t`Enter the card number`}
            inputProps={{ maxLength: 16 }}
          />
          <Controller
            name={'bank_id'}
            control={control}
            render={({ field }) => {
              let value = labelValueOptions.find((item) => item.value === field.value);
              value = value ? value : { label: '', value: 0 };

              return (
                <AutoComplete<number, string, false, undefined>
                  options={labelValueOptions}
                  fullWidth
                  value={value}
                  onChange={(_, value) => value?.value && field.onChange(value?.value)}
                  required
                  placeholder={t`Select bank name`}
                  label={t`Bank name`}
                  helperText={errors['bank_id']?.message}
                  error={!!errors['bank_id']?.message}
                />
              );
            }}
          />
        </div>
        <div className={classes.action}>
          <LoadingButton
            variant="contained"
            fullWidth
            disabled={!isValid}
            onClick={sendCardNumber(watch('code'), watch('card_number'), watch('bank_id'))}
            loading={isBankLoading}
          >
            {t`Add account`}
          </LoadingButton>
        </div>
      </FormProvider>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    gap: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 350,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    },
    color: theme.palette.grey[500],
  },
}));
