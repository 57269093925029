import axios from 'axios';
import { toast } from 'react-toastify'; // For showing notifications
import { USER_AUTH_TOKEN, USER_ROLE } from '../domain/auth/localStorageKeys';
import { notCompletedAuthModalStore } from '../stores/notCompletedAuthModalStore';

export const axiosErrorHandler = (error: unknown) => {
  if (axios.isAxiosError(error) && error.response) {
    const { status, data } = error.response;

    // Handle specific status codes
    if (status === 401) {
      // No access token or expired access token
      if ((data.auth as string).includes('احراز هویت شما باید تکمیل و تایید شود')) {
        const modalState = notCompletedAuthModalStore.getState();
        modalState.onOpen();
        modalState.onSetModalProps({
          description: 'برای دسترسی به این منبع، احراز هویت شما باید تکمیل و تایید شود.',
        });
      } else {
        toast.error('لطفا مجددا وارد پنل شوید.');
        localStorage.removeItem(USER_AUTH_TOKEN);
        localStorage.removeItem(USER_ROLE);
        window.location.href = '/auth/login'; // Redirect to login
      }
    } else if (status === 400) {
      // Handle 400 (Bad Request) errors, e.g., form validation errors
      toast.error('خطا در ارسال اطلاعات. لطفا ورودی‌های خود را بررسی کنید.');
    } else if (status === 429) {
      // Handle too many requests
      toast.error('تعداد درخواست‌ها بیش از حد مجاز است. لطفاً کمی بعد تلاش کنید.');
    } else if (status >= 500) {
      // Handle server errors (5xx)
      console.error('Server error:', error.response);
      toast.error('خطای سیستمی. لطفاً بعداً تلاش کنید.');
    }
  } else {
    // Handle generic errors (not Axios-specific)
    toast.error('خطای نامشخص. لطفاً بعداً تلاش کنید.');
    console.error('Unknown error occurred:', error);
  }

  // Reject the error to propagate it further (if needed)
  return Promise.reject(error);
};
