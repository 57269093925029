import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { Button, Grid, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { authPostKeygen, authPostKeygen as faAuthPostKeygen } from 'src/core/query/fa/authClient';
import { OTPModal } from 'src/pages/Wallet/legacy/myOTP/OTPModal';
import { ResponseType } from 'src/pages/Wallet/legacy/myOTP/useOTP';
import { ErrorCaption } from 'src/shared/components/ErrorCaption';
import { DatePicker } from 'src/shared/components/datePicker/DatePicker';
import { Select } from 'src/shared/components/select';
import { lsKeys } from 'src/shared/constants/localStorage';
import { useDialog } from 'src/shared/hooks';
import { Check } from 'src/shared/icons/Check';
import { formatDate } from 'src/shared/utils/date-utils';
import { makeStyles } from 'tss-react/mui';
import { useAuthenticationContext } from '../../authenticationTypeContext';
import { BottomOptions } from '../../components/BottomOptions';
import { useAllAuthInfo } from '../../hooks/useAllAuthInfo';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { PersonalInformationSchemaType, personalInformationSchema } from '../../schema';
import { usePersonalInfo } from './usePersonalInfo';
import { PersonalInfoFieldType, dataInputs } from './utils';
import FormTextField from 'src/shared/components/form-fields/form-text-field';
import { toast } from 'react-toastify';
import { setCaptionError } from 'src/shared/utils/error-utils';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  headerPayload?: ReactNode;
};
export const PersonalInfoStage = (props: PropsType) => {
  const { classes } = useStyles();

  const { authenticationType } = useAuthenticationContext();
  const { data: allAuthInfo, refetch: refetchAllAuthInfo } = useAllAuthInfo();
  const {
    setPersonalInfoDefaultValues,
    isLoadingPersonalInfoSubmit,
    instantSubmitPersonalInfo,
    notInstantSubmitPersonalInfo,
  } = usePersonalInfo();

  const { status, refetch: refetchStatus } = useAuthStatus();
  const [emailIsVerified, setEmailIsVerified] = useState(
    status?.identifier === 'email_verified' || status?.identifier === 'full_verified',
  );

  const {
    dialog: { open: phoneOpen, onClose: phoneOnClose },
    button: { onClick: onClickPhone },
  } = useDialog(false);

  const {
    dialog: { open: emailOpen, onClose: emailOnClose },
    button: { onClick: onClickEmail },
  } = useDialog(false);

  const methods = useForm<PersonalInformationSchemaType>({
    defaultValues: setPersonalInfoDefaultValues,
    mode: 'all',
    resolver: zodResolver(personalInformationSchema()),
  });

  const {
    control,
    formState: { errors, isValid },
    watch,
    setError,
    trigger,
  } = methods;

  const { mutateAsync: mutateVerifyIdentifiers, isLoading: isVerifyLoading } = useMutation(
    faAuthPostKeygen('/api/v1/auth/user/investor/auth/instant/verify-personalinfo'),
  );

  const handleGoBackToUploadDocuments = () => {
    props.setFormStep((step) => step - 1);
  };
  const { mutateAsync: mutateSendIdentifiers, isLoading: isSendLoadingNotInstant } = useMutation(
    authPostKeygen('/api/v1/auth/user/otp/identifiers/send'),
  );

  const { mutateAsync: mutateVerifyNotInstantIdentifiers, isLoading: isVerifyLoadingNotInstant } =
    useMutation(authPostKeygen('/api/v1/auth/user/otp/identifiers/verify'));

  const requestNotInstantOTP = (isPhone: boolean) => async (): Promise<ResponseType> => {
    const resp = await mutateSendIdentifiers({
      body: {
        user_identifier: isPhone ? watch('mobile') : watch('email'),
        user_type: isPhone ? 'contact_info_mobile' : 'contact_info_email',
      },
      params: {},
    });
    if (resp.error) {
      if (resp.response.status === 425) {
        return { data: undefined, error: { remainedTime: Number(resp.error.data?.remained_time) } };
      } else {
        toast.error((resp.error.data as any).user_identifier ?? t`Something went wrong`);
        if (resp.response.status === 400) {
          // TODO this behavior should be changed
          // instead of opening hte modal and then closing it if error occurs,
          // the we should open the modal only if the otp-request-api response is successful
          // now the problem is that the modal itself is responsible for calling the api
          emailOnClose();
          phoneOnClose();
        }
        return { data: undefined, error: { remainedTime: 0 } };
      }
    } else {
      return {
        data: {
          tempCode: Number(resp.data.data?.temp_code),
          time: Number(resp.data.data?.time) ?? 120,
        },
        error: undefined,
      };
    }
  };

  const handleGoToContactInfo = () => {
    if (
      props.disabled ||
      status?.account === 'personal_info_completed' ||
      status?.account === 'contact_info_completed' ||
      status?.account === 'bank_info_completed'
    ) {
      props.setFormStep((step) => step + 1);
      return;
    }
    if (authenticationType === 'instant') {
      onClickPhone();
    } else {
      const res = watch();
      notInstantSubmitPersonalInfo({
        body: {
          birth_date: formatDate(res.birth_date.toString(), 'fa'),
          first_name: res.first_name,
          gender: res.gender,
          last_name: res.last_name,
          national_id: res.national_id,
        },
        params: {},
      }).then((resp) => {
        if (!resp.error) {
          refetchStatus();
        } else {
          setCaptionError((resp as any).error.data, setError);
        }
      });
    }
  };

  const handleVerifyOTP = async (code: number, nuance: number) => {
    mutateVerifyIdentifiers({
      body: {
        mobile: watch('mobile'),
        temp_code: String(nuance),
        verify_code: String(code),
      },
      params: {},
    }).then((resp) => {
      if (!resp.error) {
        refetchStatus();
        phoneOnClose();
        emailOnClose();
      } else {
        toast.error((resp as any).error.data.verify_code);
      }
    });
  };

  const handleVerifyNotInstantOTP = (isPhone: boolean) => async (code: number, nuance: number) => {
    await mutateVerifyNotInstantIdentifiers({
      body: {
        user_identifier: isPhone ? watch('mobile') : watch('email'),
        code_type: isPhone ? 'contact_info_sms' : 'contact_info_email',
        temp_code: String(nuance),
        verify_code: String(code),
      },
      params: {},
    }).then((resp) => {
      if (!resp.error) {
        refetchStatus();
        refetchAllAuthInfo();
        phoneOnClose();
        emailOnClose();
        if (!isPhone) {
          setEmailIsVerified(true);
        }
      } else {
        toast.error((resp as any).error.data.verify_code);
      }
    });
  };
  const handleRequestOTP = async (): Promise<ResponseType> => {
    const res = watch();

    const resp = await instantSubmitPersonalInfo({
      body: {
        birth_date: formatDate(res.birth_date.toString(), 'fa'),
        first_name: res.first_name,
        gender: res.gender,
        last_name: res.last_name,
        national_id: res.national_id,
        mobile: res.mobile,
      },
      params: {},
    });
    if (resp.error) {
      if (resp.response.status === 425) {
        return { data: undefined, error: { remainedTime: Number(resp.error.data?.remained_time) } };
      } else {
        toast.error(t`Something went wrong`);
        return { data: undefined, error: { remainedTime: 0 } };
      }
    } else {
      return {
        data: {
          tempCode: Number(resp.data.data?.temp_code),
          time: Number(resp.data.data?.time) ?? 120,
        },
        error: undefined,
      };
    }
  };

  const makeEndAdornment = (input: PersonalInfoFieldType, onButtonClick: () => void) =>
    input.confirmed ? (
      <Typography variant="caption2" className={classes.row} color="success.main">
        <Check />
        <Typography sx={{ whiteSpace: 'nowrap' }}>{t`Confirmed`}</Typography>
      </Typography>
    ) : (
      <Button
        onClick={onButtonClick}
        variant="contained"
        size="small"
        disabled={!!errors[input.name] || String(watch(input.name)).length < 1}
      >{t`Confirm`}</Button>
    );
  return (
    <FormProvider {...methods}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.titleContainer}>
            <div className={classes.actionBar}>
              <Typography variant="h3">{t`Personal information`}</Typography>
              {props.headerPayload}
            </div>
            {authenticationType === 'instant' ? (
              <Typography variant="body2" color={'neutral.darkGrey'}>
                اطلاعات این فرم بر اساس تصویر کارت ملی بارگذاری شده در مرحله قبل به شکل خودکار پرشده
                است، همچنین شماره تلفن همراه باید متعلق به فرد متقاضی باشد.
              </Typography>
            ) : (
              <>
                <Typography variant="body2" color={'neutral.darkGrey'}>
                  {t`Personal information is confirmed if it matches with the national card. This case will be investigated by the admin.`}
                </Typography>
                <Typography variant="body2" color={'neutral.darkGrey'}>
                  همچنین شماره تلفن همراه اعلامی باید متعلق به فرد متقاضی باشد.{' '}
                </Typography>
              </>
            )}
          </div>
          <Grid container spacing={3}>
            {dataInputs({
              status,
              allAuthInfo: allAuthInfo?.data,
              watch,
            }).map((dataInput) => (
              <Grid key={dataInput.label} item xs={12} sm={6}>
                {dataInput.name === 'email' ? (
                  <FormTextField
                    type="email"
                    name={dataInput.name}
                    label={dataInput.label}
                    placeholder={dataInput.placeholder}
                    disabled={dataInput.disable || props.disabled}
                    endAdornment={makeEndAdornment(dataInput, onClickEmail)}
                  />
                ) : dataInput.name === 'mobile' ? (
                  <FormTextField
                    type="tel"
                    name={dataInput.name}
                    label={dataInput.label}
                    placeholder={dataInput.placeholder}
                    disabled={dataInput.disable || props.disabled}
                    onChange={(v) =>
                      authenticationType === 'instant' &&
                      v.toString().length === 11 &&
                      trigger('mobile')
                    }
                    endAdornment={
                      authenticationType === 'notinstant' &&
                      (dataInput.confirmed ? (
                        <Typography variant="caption2" className={classes.row} color="success.main">
                          <Check />
                          <Typography sx={{ whiteSpace: 'nowrap' }}>{t`Confirmed`}</Typography>
                        </Typography>
                      ) : (
                        <Button
                          onClick={onClickPhone}
                          variant="contained"
                          size="small"
                          disabled={
                            !!errors[dataInput.name] || (watch('mobile') ?? '').length !== 11
                          }
                        >{t`Confirm`}</Button>
                      ))
                    }
                  />
                ) : dataInput.type === 'text' ? (
                  <FormTextField
                    name={dataInput.name}
                    label={dataInput.label}
                    placeholder={dataInput.placeholder}
                    type={dataInput.name === 'national_id' ? 'tel' : 'text'}
                    inputProps={dataInput.name === 'national_id' ? { maxLength: 12 } : undefined}
                    disabled={props.disabled || authenticationType === 'instant'}
                  />
                ) : dataInput.type === 'select' ? (
                  <Controller
                    name={dataInput.name}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Select
                          fullWidth
                          required
                          value={(field.value as string) ?? 'male'}
                          onChange={field.onChange}
                          placeholder={dataInput.placeholder}
                          label={dataInput.label}
                          disabled={props.disabled}
                          options={[
                            { value: 'male', label: t`Male` },
                            { value: 'female', label: t`Female` },
                          ]}
                        />
                        <ErrorCaption caption={errors[dataInput.name]?.message} />
                      </div>
                    )}
                  />
                ) : (
                  <Controller
                    name={dataInput.name}
                    control={control}
                    render={({ field }) => (
                      <div>
                        <DatePicker
                          label={dataInput.label}
                          value={field.value}
                          onChange={field.onChange}
                          className={classes.datePicker}
                          maxDate={new Date()}
                          disabled={props.disabled || authenticationType === 'instant'}
                          slotProps={{
                            textField: { color: 'secondary', required: true },
                            desktopPaper: {
                              sx: { boxShadow: '0px -16px 48px 0px rgba(11, 18, 38, 0.04)' },
                            },
                          }}
                        />
                        <ErrorCaption caption={errors[dataInput.name]?.message} />
                      </div>
                    )}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          {authenticationType === 'instant' ? (
            <OTPModal
              ui={{
                title: t`Confirm phone number`,
                description: (
                  <>
                    یک کد 5 رقمی به شماره <em> {watch('mobile')}</em>
                    ارسال گردید. کد را جهت تأیید شماره خود وارد کنید.{' '}
                  </>
                ),
              }}
              open={phoneOpen}
              onClose={phoneOnClose}
              lsKey={lsKeys.fa.auth.instantSubmit}
              requestOtpResponse={handleRequestOTP}
              verifyOTP={handleVerifyOTP}
              isLoading={isVerifyLoading || isLoadingPersonalInfoSubmit}
            />
          ) : (
            <OTPModal
              ui={{
                title: t`Confirm phone number`,
                description: (
                  <>
                    یک کد 5 رقمی به شماره
                    <em>{watch('mobile')}</em>
                    ارسال گردید. کد را جهت تأیید شماره خود وارد کنید.{' '}
                  </>
                ),
              }}
              open={phoneOpen}
              onClose={phoneOnClose}
              lsKey={lsKeys.fa.auth.notInstantPhone}
              requestOtpResponse={requestNotInstantOTP(true)}
              verifyOTP={handleVerifyNotInstantOTP(true)}
              isLoading={isSendLoadingNotInstant || isVerifyLoadingNotInstant}
            />
          )}
          <OTPModal
            ui={{
              title: t`Confirm email address`,
              description: (
                <>
                  یک کد 5 رقمی به ایمیل <em>{watch('email')}</em>
                  ارسال گردید. کد را جهت تأیید ایمیل خود وارد کنید.{' '}
                </>
              ),
            }}
            open={emailOpen}
            onClose={emailOnClose}
            lsKey={lsKeys.fa.auth.notInstantEmail}
            requestOtpResponse={requestNotInstantOTP(false)}
            verifyOTP={handleVerifyNotInstantOTP(false)}
            isLoading={isSendLoadingNotInstant || isVerifyLoadingNotInstant}
          />
        </div>
        <BottomOptions
          onPreviousClick={handleGoBackToUploadDocuments}
          onNextClick={handleGoToContactInfo}
          nextDisabled={!isValid || !emailIsVerified}
          isNextLoading={isLoadingPersonalInfoSubmit}
        />
      </div>
    </FormProvider>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
    width: '100%',
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 728,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  datePicker: {
    width: '100%',
  },
}));
