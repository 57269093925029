import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { MyAxios } from 'src/core/axios/requests';
import { getAppBaseUrl } from 'src/locales/utils';
import useFirstRenderEffect from 'src/shared/hooks/use-first-render-effect';
import { useAuthenticationContext } from '../../authenticationTypeContext';
import { PersonalImageType } from '../../types';

type DocType = 'first' | 'second';

type UploadProgressState = {
  first: null | number;
  second: null | number;
};

export const useDocuments = () => {
  const { authenticationType } = useAuthenticationContext();
  const [uploadProgress, setUploadProgress] = useState<UploadProgressState>({
    first: null,
    second: null,
  });

  const [documents, setDocuments] = useState<PersonalImageType>({
    second: null,
    first: null,
  });

  useFirstRenderEffect(() => {
    MyAxios.getFile('/api/v1/auth/user/investor/info/docs').then(({ data: { data } }) => {
      // TODO this part is very risky, because we have no typescript
      setDocuments(
        authenticationType === 'instant'
          ? {
              first: data.identity_doc1_temp_address,
              second: data.identity_doc2_temp_address,
            }
          : {
              first: data.birth_certificate_temp_address || data.identity_doc1_temp_address,
              second: data.unofficial_nid_card_temp_address || data.identity_doc2_temp_address,
            },
      );
    });
  });

  const docTypeHelper = {
    instant: {
      first: 'cardFront',
      second: 'cardBack',
    },
    notinstant: {
      first: 'identityDoc1',
      second: 'identityDoc2',
    },
  };

  const updateProgressValue = (docType: DocType, value: number | null) => {
    setUploadProgress((state) => ({ ...state, [docType]: value }));
  };

  const updateDocument = (docType: DocType, value: string | null) => {
    setDocuments((images) => ({ ...images, [docType]: value }));
  };

  const handleOnDocumentChange = (docType: 'first' | 'second') => (value: File | null) => {
    const shouldRemoveCurrentDocument = !value;

    if (shouldRemoveCurrentDocument) {
      updateDocument(docType, null);
    } else {
      updateDocument(docType, URL.createObjectURL(value));
      const formData = new FormData();
      formData.append('image', value);
      updateProgressValue(docType, 1);
      MyAxios.uploadFormData(
        `/api/v1/auth/user/investor/auth/${authenticationType}/identitydocs`,
        formData,
        {
          params: { docType: docTypeHelper[authenticationType][docType] },
          onUploadProgress: (ev) =>
            updateProgressValue(docType, Math.round((ev.loaded * 100) / (ev.total ?? 1))),
        },
      )
        .then((resp) => {
          updateDocument(docType, getAppBaseUrl() + resp.data.data.temp_address);
        })
        .catch((e: AxiosError<any>) => {
          if (e.status === 400 && Object.values(e.response?.data?.data || {}).length) {
            toast.error(String(Object.values(e.response!.data!.data || {})[0]));
          }
          updateDocument(docType, null);
        })
        .finally(() => {
          updateProgressValue(docType, null);
        });
    }
  };

  return { documents, uploadProgress, handleOnDocumentChange };
};
