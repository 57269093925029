import { t } from '@lingui/macro';
import { Button, Dialog, Typography, alpha } from '@mui/material';
import * as React from 'react';
import { FormSubmitted } from 'src/shared/icons/FormSubmitted';
import { makeStyles } from 'tss-react/mui';

interface OwnProps {
  onClose: () => void;
  open: boolean;
  title: string;
  buttonCaption?: string;
  body: JSX.Element | string;
}
export const SuccessModal = (props: OwnProps) => {
  const { classes } = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <React.Fragment>
        <div className={classes.content}>
          <FormSubmitted />
          <Typography variant="h3" textAlign={'center'} mt={3}>
            {props.title}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {props.body}
          </Typography>
        </div>
        <div className={classes.action}>
          <Button variant="contained" fullWidth onClick={props.onClose}>
            {props.buttonCaption ?? t`Continue`}
          </Button>
        </div>
      </React.Fragment>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },

  description: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
    textAlign: 'center',
    '& em': {
      ...theme.typography.body3,
      fontStyle: 'normal',
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    gap: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 390,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
}));
