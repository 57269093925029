import { t } from '@lingui/macro';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { PersonalImagePicker } from 'src/shared/components/PersonalImagePicker';
import { useMedia } from 'src/shared/hooks';
import { makeStyles } from 'tss-react/mui';
import { BottomOptions } from '../../components/BottomOptions';
import { useDocuments } from './useDocuments';
import { warnings } from './utils';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
};

export const UploadDocumentsStage = (props: PropsType) => {
  const { classes } = useStyles();
  const isUpSm = useMedia('sm');

  const { handleOnDocumentChange, documents, uploadProgress } = useDocuments();

  const handleGoToPersonalInformation = () => {
    props.setFormStep((step) => step + 1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <Typography variant="h3">{t`Upload documents`}</Typography>
          <List className={classes.list}>
            {warnings.map((warning) => (
              <ListItem key={String(warning)} className={classes.listItem}>
                <Typography className={classes.textEm} variant="body2" color={'neutral.darkGrey'}>
                  {warning}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
        <div className={classes.imageContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <PersonalImagePicker
                src={documents.birthCertificate}
                onChangeFile={handleOnDocumentChange('birthCertificate')}
                progress={uploadProgress.birthCertificate}
                title={t`The picture on the front of the national card or the first page of the birth certificate*`}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PersonalImagePicker
                src={documents.unofficialNidCard}
                progress={uploadProgress.unofficialNidCard}
                onChangeFile={handleOnDocumentChange('unofficialNidCard')}
                disabled={props.disabled}
                title={t`The image on the back of the national card or receipt of the national card*`}
              />
            </Grid>
          </Grid>

          {isUpSm ? (
            <div className={classes.imageFooter}>
              <Typography variant="caption2" color={'neutral.darkGrey'}>
                {t`PNG, JPG, JPEG image format`}
              </Typography>
              <span className={classes.span}>●</span>
              <Typography variant="caption2" color={'neutral.darkGrey'}>
                {t`The size of the image should be less than 5 MB`}
              </Typography>
            </div>
          ) : (
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Typography variant="caption2" color={'neutral.darkGrey'}>
                  {t`PNG, JPG, JPEG image format`}
                </Typography>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Typography variant="caption2" color={'neutral.darkGrey'}>
                  {t`The size of the image should be less than 5 MB`}
                </Typography>
              </ListItem>
            </List>
          )}
        </div>
      </div>
      <BottomOptions
        nextDisabled={!documents.birthCertificate || !documents.unofficialNidCard}
        onNextClick={handleGoToPersonalInformation}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  list: { listStyleType: 'disc', paddingLeft: 2, color: 'neutral.darkGrey' },
  listItem: { display: 'list-item', padding: 0, paddingBottom: 0.5 },
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    width: '100%',
    overflowY: 'scroll',
  },
  content: {
    maxWidth: 728,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    overflowY: 'scroll',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  span: { fontSize: 10, color: theme.palette.neutral.lightBlue },
  imageFooter: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  textEm: {
    '& > em': {
      fontStyle: 'bold',
      marginRight: theme.spacing(1),
      color: theme.palette.common.black,
    },
  },
}));
