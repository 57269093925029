import { makeStyles } from 'tss-react/mui';
import { BottomOptions } from '../../components/BottomOptions';
import { useGetAuthStatus } from '../../hooks/useGetAuthStatus';
import { AddCard } from './components/AddCard';
import { BankCard } from './components/BankCard';
import { Header } from './components/Header';
import { useBankDialog } from './hooks/useBankDialog';
import { useBankInfo } from './hooks/useBankInfo';
import { useSuccessDialog } from './hooks/useSuccessDialog';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
};
export const BankInformationStage = (props: PropsType) => {
  const { classes } = useStyles();

  const { refetch, isLoading: isStatusLoading } = useGetAuthStatus();

  const { bankInfo, bankInfoRefetch, isLoadingAuthForm, handleDelete, submitAuthForm } =
    useBankInfo();

  const {
    Component: SuccessDialogComponent,
    data: { successModalOnClick },
  } = useSuccessDialog();

  const {
    Component: BankDialogComponent,
    data: { bankModalOnClick },
  } = useBankDialog({
    accountNumber: bankInfo?.data?.accounts[0]?.account_number,
    handleOnClose: bankInfoRefetch,
  });

  const handleGoBackToContactInformation = () => {
    props.setFormStep(2);
  };
  const handleSubmit = () => {
    submitAuthForm({
      params: {},
    }).then(() => refetch().then(successModalOnClick));
  };

  const handleOpen = () => {
    if (!props.disabled) bankModalOnClick();
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Header />
        {bankInfo?.data?.accounts[0] ? (
          <BankCard
            bankName={bankInfo?.data?.accounts[0].bank_name}
            accountText={bankInfo?.data?.accounts[0]?.account_number}
            handleDelete={handleDelete}
            disabled={props.disabled}
          />
        ) : (
          <AddCard handleOpen={handleOpen} />
        )}
      </div>
      {BankDialogComponent}
      {SuccessDialogComponent}
      <BottomOptions
        onPreviousClick={handleGoBackToContactInformation}
        nextDisabled={!bankInfo?.data?.accounts[0] || props.disabled}
        onNextClick={handleSubmit}
        isNextLoading={isLoadingAuthForm || isStatusLoading}
        submit={true}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 728,
      minWidth: 728,
    },
  },
}));
