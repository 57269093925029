import { makeStyles } from 'tss-react/mui';
import { BottomOptions } from '../../components/BottomOptions';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { AddCard } from './components/AddCard';
import { BankCard } from './components/BankCard';
import { Header } from './components/Header';
import { useBankDialog } from './hooks/useBankDialog';
import { useBankInfo } from './hooks/useBankInfo';
import { useSuccessDialog } from './hooks/useSuccessDialog';
import { ReactNode } from 'react';
import { ConditionalRender } from 'src/shared/components/ConditionalRender';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  headerPayload?: ReactNode;
};
export const BankInformationStage = (props: PropsType) => {
  const { classes } = useStyles();

  const { refetch, isLoading: isStatusLoading } = useAuthStatus();
  const {
    bankInfo,
    bankInfoRefetch,
    isLoadingAuthForm,
    isLoadingBankInfo,
    handleDelete,
    submitAuthForm,
    isLoadingDeleteCardNumber,
  } = useBankInfo();

  const {
    Component: SuccessDialogComponent,
    data: { successModalOnClick },
  } = useSuccessDialog();

  const {
    Component: BankDialogComponent,
    data: { bankModalOnClick },
  } = useBankDialog({
    cardNumber: bankInfo?.data?.accounts[0]?.card_number,
    handleOnClose: bankInfoRefetch,
  });

  const handleGoBackToContactInformation = () => {
    props.setFormStep(2);
  };
  const handleSubmit = () => {
    submitAuthForm({
      params: {},
    }).then((resp) => {
      console.log('in then with resp: ', resp);
      if (!(resp as any).error) {
        refetch().then(successModalOnClick);
      }
    });
  };

  const handleOpen = () => {
    if (!props.disabled) bankModalOnClick();
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Header>{props.headerPayload}</Header>
        <ConditionalRender loading={isLoadingBankInfo}>
          {bankInfo?.data?.accounts[0] ? (
            <BankCard
              handleDelete={handleDelete}
              disabled={props.disabled}
              isLoadingDelete={isLoadingDeleteCardNumber}
              cardData={bankInfo?.data?.accounts[0]}
            />
          ) : (
            <AddCard handleOpen={handleOpen} />
          )}
        </ConditionalRender>
      </div>
      {BankDialogComponent}
      {SuccessDialogComponent}
      <BottomOptions
        onPreviousClick={handleGoBackToContactInformation}
        nextDisabled={!bankInfo?.data?.accounts[0] || props.disabled}
        onNextClick={handleSubmit}
        isNextLoading={isLoadingAuthForm || isStatusLoading}
        submit={true}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    width: '100%',
    maxWidth: 728,
  },
}));
