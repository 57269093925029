import { Divider, DrawerProps, Drawer as MuiDrawer } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { drawerWidth } from './constants';

export const DesktopDrawer = (props: DrawerProps) => {
  const { classes } = useStyles();
  return (
    <MuiDrawer variant="persistent" open classes={{ root: classes.drawer }} {...props}>
      <Logo variant="dense" />
      <Divider
        sx={(theme) => ({
          marginTop: 3,
          borderColor: theme.palette.neutral.lightenDark,
          borderWidth: 1.5,
          borderStyle: 'dashed',
          mx: 4,
        })}
      />
      <Menu />
    </MuiDrawer>
  );
};
const useStyles = makeStyles()((theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: drawerWidth,
      background: theme.palette.neutral.dark,
      color: theme.palette.common.white,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      overflowY: 'overlay' as any,
    },
  },
}));
