import { useQuery } from 'react-query';
import { sharedAuthQueryKeygen } from 'src/core/query/sharedKeygen';

type PropsType = {
  countryId: number;
  provinceId: number;
};
export const useFormData = (props: PropsType) => {
  const { data: provinceData } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/province', {
      params: {
        header: {},
        query: { countryId: 1 },
      },
    }),
  });
  const { data: cityData } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/city', {
      params: { header: {}, query: { provinceId: Number(props.provinceId) ?? 0 } },
    }),
    enabled: !!props.provinceId,
  });
  return {
    provinceData,
    cityData,
  };
};
