import { useUser } from 'src/shared/contexts/user/user.context';
import { useAuthenticationContext } from '../authenticationTypeContext';

export const useAuthStatus = () => {
  const { fetchUserInfo, isPending, user } = useUser();
  const { authenticationType, setAuthenticationType } = useAuthenticationContext();

  const handleRefetch = async () => {
    return fetchUserInfo().then((user) => {
      if (user.status?.auth_type !== authenticationType) {
        setAuthenticationType(user.status?.auth_type ?? 'instant');
      }
    });
  };
  return {
    refetch: handleRefetch,
    isLoading: isPending,
    status: user?.status,
  };
};
