import { t } from '@lingui/macro';
import { isAppLocaleFa } from 'src/locales/utils';
import * as yup from 'yup';
import { emailRegex, mobileNumberRegex } from '../regex/textRegex';

export const MESSAGE_FILE_TYPES = '.jpg, .jpeg, .png, .webp, .pdf';
export const MESSAGE_IMAGE_TYPES = '.jpg, .jpeg, .png, .webp';

export const messages = {
  required: t`Field is required`,
  number: t`Please enter a valid number`,
  email: t`Please enter a valid email address`,
  stringArray: t`Please enter at least one item`,
  mobile: t`Please enter a valid phone number`,
  regex: t`Please enter a valid value`,
  file: {
    maxFileSize: t`Max file size is ${5}MB.`,
    validExtensions: (extensions: string) => t`Supported formats` + ': ' + extensions,
  },
};

const MAX_FILE_SIZE = 5 * 1000 * 1000;
const ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
];
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

export const customValidations = {
  boolean: () => yup.boolean(),
  booleanRequired: () => yup.boolean().required(messages.required),

  string: () => yup.string().transform((_, value) => value.trim()),
  stringRequired: (minLength = 1) =>
    yup
      .string()
      .transform((_, value) => value.trim())
      .required(messages.required)
      .min(minLength, t`String length is less that ${minLength} characters`),

  emailOrPhoneRequired: () =>
    yup
      .string()
      .transform((_, value) => value.trim())
      .required(messages.required)
      .test(
        'email_or_phone',
        isAppLocaleFa() ? t`Enter a valid email or phone number` : t`Enter a valid email`,
        (value) => {
          return isAppLocaleFa()
            ? emailRegex.test(value) || mobileNumberRegex.test(value)
            : emailRegex.test(value);
        },
      ),

  stringMatchesRegexRequired: (regex: RegExp, customMessage?: string) =>
    yup
      .string()
      .required(messages.required)
      .matches(regex, customMessage || messages.regex),

  stringArrayMatchesRegexRequired: (regex: RegExp, customMessage?: string) =>
    yup
      .array()
      .of(
        yup
          .string()
          .required(messages.required)
          .matches(regex, customMessage || messages.regex),
      )
      .required(messages.required)
      .min(1, messages.stringArray),

  stringArray: () => yup.array().of(yup.string()),
  stringArrayRequired: () =>
    yup
      .array()
      .of(yup.string().required())
      .min(1, messages.stringArray)
      .required(messages.stringArray),

  number: () =>
    yup
      .number()
      .transform((_, value) => (Number(value) && Number(value) > 0 ? Number(value) : null))
      .nullable()
      .typeError(messages.number)
      .positive(messages.number),

  numberRequired: () =>
    yup
      .number()
      .transform((_, value) => (Number(value) && Number(value) > 0 ? Number(value) : null))
      .typeError(messages.number)
      .positive(messages.number)
      .required(messages.required),

  numberIncludeZero: () =>
    yup
      .number()
      .transform((_, value) => (Number(value) || value == 0 ? Number(value) : null))
      .nullable()
      .typeError(messages.number)
      .moreThan(-1, messages.number),

  numberIncludeZeroRequired: () =>
    yup
      .number()
      .transform((_, value) => (Number(value) || value == 0 ? Number(value) : null))
      .nullable()
      .typeError(messages.number)
      .moreThan(-1, messages.number)
      .required(messages.required),

  numberArray: () => yup.array().of(yup.number()),
  numberArrayRequired: (minItemCount: number = 1) =>
    yup
      .array()
      .of(yup.number().required(messages.required))
      .min(minItemCount, messages.stringArray)
      .required(messages.stringArray),

  email: () => yup.string().email(messages.email),
  emailRequired: () => yup.string().email(messages.email).required(messages.required),
  file: () =>
    yup
      .mixed<File>()
      .test('fileSize', messages.file.maxFileSize, (file) => {
        if (!file) return true;
        return file && file.size <= MAX_FILE_SIZE;
      })
      .test('fileFormat', messages.file.validExtensions(MESSAGE_FILE_TYPES), (file) => {
        if (!file) return true;
        return file && ACCEPTED_FILE_TYPES.includes(file.type);
      }),
  fileRequired: () =>
    yup
      .mixed<File>()
      .test('fileSize', messages.file.maxFileSize, (file) => {
        return file && file.size <= MAX_FILE_SIZE;
      })
      .test('fileFormat', messages.file.validExtensions(MESSAGE_FILE_TYPES), (file) => {
        return file && ACCEPTED_FILE_TYPES.includes(file.type);
      })
      .required(messages.required),

  image: () =>
    yup
      .mixed<File>()
      .test('imageSize', messages.file.maxFileSize, (image) => {
        if (!image) return true;
        return image && image.size <= MAX_FILE_SIZE;
      })
      .test('imageFormat', messages.file.validExtensions(MESSAGE_IMAGE_TYPES), (image) => {
        if (!image) return true;
        return image && ACCEPTED_IMAGE_TYPES.includes(image.type);
      }),
  imageRequired: () =>
    yup
      .mixed<File>()
      .test('imageSize', messages.file.maxFileSize, (image) => {
        return image && image.size <= MAX_FILE_SIZE;
      })
      .test('imageFormat', messages.file.validExtensions(MESSAGE_IMAGE_TYPES), (image) => {
        return image && ACCEPTED_IMAGE_TYPES.includes(image.type);
      })
      .required(messages.required),
};
