import { t } from '@lingui/macro';
import {
  emptyOrNumberRegex,
  faPostalCodeRegex,
  numRegex,
  stringRegex,
} from 'src/shared/regex/textRegex';
import { z } from 'zod';

export const personalInformationSchema = () =>
  z.object({
    first_name: z
      .string()
      .min(1, t`Field is required`)
      .regex(stringRegex, t`Invalid character`),
    last_name: z
      .string()
      .min(1, t`Field is required`)
      .regex(stringRegex, t`Invalid character`),
    national_id: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .min(8, t`Enter between 8 and 12 digits`)
      .max(12, t`Enter between 8 and 12 digits`),
    gender: z.enum(['male', 'female']),
    birth_date: z.date({ required_error: t`Field is required` }),
    email: z
      .string()
      .email(t`Invalid email address`)
      .min(1, t`Field is required`),
    mobile: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .length(11, `دقیقا ۱۱ عدد وارد کنید`),
  });

export type PersonalInformationSchemaType = z.infer<ReturnType<typeof personalInformationSchema>>;

export const contactInformationSchema = () =>
  z.object({
    address: z.string().min(1, t`Field is required`),
    city_id: z.number({ required_error: t`Field is required` }),
    country_id: z.number({ required_error: t`Field is required` }),
    home_phone: z
      .string()
      .regex(emptyOrNumberRegex, t`Please enter digits`)
      .refine((val) => val.length === 0 || val.length === 11, {
        message: t`Enter exactly 11 digits`,
      })
      .transform((e) => (e === '' ? undefined : e))
      .optional(),
    postal_code: z
      .string()
      .refine((val) => faPostalCodeRegex.test(val), { message: t`Invalid character` })
      .refine((val) => val.length === 10, { message: t`Enter exactly 10 digits` }),
    province_id: z.number({ required_error: t`Field is required` }),
  });
export type ContactInformationSchemaType = z.infer<ReturnType<typeof contactInformationSchema>>;

export const verifyContactCodeSchema = () =>
  z.object({
    code: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .length(5, t`Enter exactly 5 digits`),
  });
export type VerifyContactCodeSchemaType = z.infer<ReturnType<typeof verifyContactCodeSchema>>;
