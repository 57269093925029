import { createContext, useContext, useState } from 'react';
import { AuthenticationType } from './types';
import { useUser } from 'src/shared/contexts/user/user.context';

type AuthenticationContextType = {
  authenticationType: AuthenticationType;
  setAuthenticationType: React.Dispatch<React.SetStateAction<AuthenticationType>>;
};

const AuthenticationTypeContext = createContext<AuthenticationContextType | null>(null);

export const AuthenticationTypeProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const [authenticationType, setAuthenticationType] = useState<AuthenticationType>(
    // TODO user type is different in Fa locale and En locale
    (user as any)?.status?.auth_type || 'notinstant',
  );

  return (
    <AuthenticationTypeContext.Provider value={{ authenticationType, setAuthenticationType }}>
      {children}
    </AuthenticationTypeContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  return useContext(AuthenticationTypeContext) as AuthenticationContextType;
};
