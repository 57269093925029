import { t } from '@lingui/macro';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { PersonalImagePicker } from 'src/shared/components/PersonalImagePicker';
import { useMedia } from 'src/shared/hooks';
import { makeStyles } from 'tss-react/mui';
import { BottomOptions } from '../../components/BottomOptions';
import { useDocuments } from './useDocuments';
import { warnings } from './utils';
import { ReactNode } from 'react';
import { useAuthenticationContext } from '../../authenticationTypeContext';
import { useAuthStatus } from '../../hooks/useAuthStatus';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  headerPayload?: ReactNode;
};

export const UploadDocumentsStage = (props: PropsType) => {
  const { classes } = useStyles();
  const isUpSm = useMedia('sm');

  const { authenticationType: authType } = useAuthenticationContext();
  const { handleOnDocumentChange, documents, uploadProgress } = useDocuments();
  const { refetch: refetchStatus } = useAuthStatus();

  const handleGoToPersonalInformation = () => {
    refetchStatus();
    props.setFormStep((step) => step + 1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <div className={classes.actionBar}>
            <Typography variant="h3">{t`Upload documents`}</Typography>
            {props.headerPayload}
          </div>
          {authType === 'instant' ? (
            <Typography className={classes.textEm} variant="body2" color={'neutral.dark'}>
              <b> توجه! &nbsp;</b>
              فرآیند احراز هویت آنی شما با استفاده از سامانه‌های برخط، بلافاصله و در کمترین زمان
              انجام می‌شود. برای انجام احراز هویت آنی، نیاز به بارگذاری تصویر رو و پشت کارت ملی
              هوشمند، شماره تلفن همراه به نام خود و اطلاعات حساب بانکی متعلق به خود دارید. چنان‌چه
              کارت ملی هوشمند در اختیار ندارید یا در هر مرحله از احراز هویت به دلیل مشکلات ارتباطی
              امکان انجام احراز هویت آنی نباشد، می‌توانید فرآیند احراز هویت خود را به شیوه غیرآنی
              تبدیل کنید تا همکاران ما در بخش پشتیبانی مدارک شما را ارزیابی و هویت شما را حداکثر ظرف
              2 روز کاری احراز نمایند.
            </Typography>
          ) : (
            <List className={classes.list}>
              {warnings.map((warning) => (
                <ListItem key={String(warning)} className={classes.listItem}>
                  <Typography className={classes.textEm} variant="body2" color={'neutral.darkGrey'}>
                    {warning}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <div className={classes.imageContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <PersonalImagePicker
                src={documents.first}
                onChangeFile={handleOnDocumentChange('first')}
                progress={uploadProgress.first}
                title={
                  authType === 'instant'
                    ? 'تصویر روی کارت ملی*'
                    : t`The picture on the front of the national card or the first page of the birth certificate*`
                }
                disabled={props.disabled || (authType === 'instant' && !!documents.first)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PersonalImagePicker
                src={documents.second}
                progress={uploadProgress.second}
                onChangeFile={handleOnDocumentChange('second')}
                title={
                  authType === 'instant'
                    ? 'تصویر پشت کارت ملی*'
                    : t`The image on the back of the national card or receipt of the national card*`
                }
                disabled={props.disabled || (authType === 'instant' && !!documents.second)}
              />
            </Grid>
          </Grid>

          {isUpSm ? (
            <div className={classes.imageFooter}>
              <Typography variant="caption2" color={'neutral.darkGrey'}>
                {t`PNG, JPG, JPEG image format`}
              </Typography>
              <span className={classes.span}>●</span>
              <Typography variant="caption2" color={'neutral.darkGrey'}>
                {t`The size of the image should be less than 5 MB`}
              </Typography>
            </div>
          ) : (
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Typography variant="caption2" color={'neutral.darkGrey'}>
                  {t`PNG, JPG, JPEG image format`}
                </Typography>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Typography variant="caption2" color={'neutral.darkGrey'}>
                  {t`The size of the image should be less than 5 MB`}
                </Typography>
              </ListItem>
            </List>
          )}
        </div>
      </div>
      <BottomOptions
        // nextDisabled={!documents.first || !documents.second}
        // this check is a temporary solution for preventing the button being enabled too early
        // because the previous check lets the button to be enabled even if the upload progress
        // is not completed yet (the documents links are present locally before being replaced
        // with server response links)
        // TODO should be replaced with a better solution after optimizing the code
        nextDisabled={!documents.first?.startsWith('http') || !documents.second?.startsWith('http')}
        onNextClick={handleGoToPersonalInformation}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  list: { listStyleType: 'disc', paddingLeft: 2, color: 'neutral.darkGrey' },
  listItem: { display: 'list-item', padding: 0, paddingBottom: 0.5 },
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    width: '100%',
    overflowY: 'scroll',
  },
  content: {
    maxWidth: 728,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    overflowY: 'scroll',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  span: { fontSize: 10, color: theme.palette.neutral.lightBlue },
  imageFooter: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  textEm: {
    '& > em': {
      fontStyle: 'bold',
      marginRight: theme.spacing(1),
      color: theme.palette.common.black,
    },
  },

  actionBar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
}));
