export const WarningDarkBackground = () => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M96 48C96 61.4173 90.5089 73.5118 81.7041 82.2047C73.0888 90.8032 61.1598 96 48 96C34.9349 96 23.0059 90.7087 14.2959 82.2047C5.49112 73.5118 0 61.4173 0 48C0 21.4488 21.4911 0 48 0C74.5089 0 96 21.5433 96 48Z"
      fill="#E7EEF9"
    />
    <mask
      id="mask0_4248_1665"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="96"
      height="96"
    >
      <path
        d="M96 48C96 61.4173 90.5089 73.5118 81.7041 82.2047C73.0888 90.8032 61.1598 96 48 96C34.9349 96 23.0059 90.7087 14.2959 82.2047C5.49112 73.5118 0 61.4173 0 48C0 21.4488 21.4911 0 48 0C74.5089 0 96 21.5433 96 48Z"
        fill="#E7EEF9"
      />
    </mask>
    <g mask="url(#mask0_4248_1665)">
      <path
        d="M79.28 22C81.4008 22 83.12 23.7192 83.12 25.84V83.421C74.2858 92.4505 62.0539 98.8 48.56 98.8C35.1631 98.8 22.9312 92.3432 14 83.4129V25.84C14 23.7192 15.7192 22 17.84 22H79.28Z"
        fill="url(#paint0_linear_4248_1665)"
      />
      <path
        d="M44.6151 35.3761C46.1839 32.8844 49.8161 32.8844 51.3849 35.3761L61.1396 50.8687C62.8167 53.5324 60.9024 57 57.7547 57H38.2453C35.0976 57 33.1833 53.5324 34.8604 50.8687L44.6151 35.3761Z"
        fill="#545F7E"
      />
      <path
        d="M48 49C50.2091 49 52 47.2091 52 45C52 42.7909 50.2091 41 48 41C45.7909 41 44 42.7909 44 45C44 47.2091 45.7909 49 48 49Z"
        fill="#FF800B"
      />
      <path
        d="M56 56.9261C56 56.9261 51.1437 56.9261 48 56.9261C44.8563 56.9261 40 56.9261 40 56.9261C40 56.9261 44.275 52 48 52C51.725 52 56 56.9261 56 56.9261Z"
        fill="#FF800B"
      />
    </g>
    <path
      d="M61.4402 65.28H34.5602C33.4998 65.28 32.6402 66.1396 32.6402 67.2C32.6402 68.2604 33.4998 69.12 34.5602 69.12H61.4402C62.5006 69.12 63.3602 68.2604 63.3602 67.2C63.3602 66.1396 62.5006 65.28 61.4402 65.28Z"
      fill="#545F7E"
    />
    <path
      d="M61.4402 72.9599H34.5602C33.4998 72.9599 32.6402 73.8195 32.6402 74.8799C32.6402 75.9403 33.4998 76.7999 34.5602 76.7999H61.4402C62.5006 76.7999 63.3602 75.9403 63.3602 74.8799C63.3602 73.8195 62.5006 72.9599 61.4402 72.9599Z"
      fill="#545F7E"
    />
    <path
      d="M53.7602 80.6399H42.2402C41.1798 80.6399 40.3202 81.4996 40.3202 82.5599C40.3202 83.6203 41.1798 84.4799 42.2402 84.4799H53.7602C54.8206 84.4799 55.6802 83.6203 55.6802 82.5599C55.6802 81.4996 54.8206 80.6399 53.7602 80.6399Z"
      fill="#545F7E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4248_1665"
        x1="14.1072"
        y1="22.1826"
        x2="84.7941"
        y2="97.1206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#001A70" />
        <stop offset="1" stop-color="#0B1226" />
      </linearGradient>
    </defs>
  </svg>
);
