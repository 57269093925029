import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  ContactInformationSchemaType,
  contactInformationSchema,
} from 'src/pages/AuthenticationPage/FaAuthenticationPage/schema';

export const useSetFormData = (refetch: any) => {
  return useForm<ContactInformationSchemaType>({
    defaultValues: async () => {
      return refetch().then(({ data }: { data: any }) => {
        return {
          address: data?.data?.address ?? '',
          home_phone: data?.data?.home_phone ?? '',
          postal_code: data?.data?.postal_code ?? '',
          city_id: Number(data?.data?.city_id) ?? 0,
          country_id: Number(data?.data?.country_id) ?? 0,
          province_id: Number(data?.data?.province_id) ?? 0,
        };
      });
    },
    mode: 'all',
    resolver: zodResolver(contactInformationSchema()),
  });
};
