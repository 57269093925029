import { Chip, ChipProps } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

export interface ChipTabProps {
  active?: boolean;
  onClick?: ChipProps['onClick'];
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
}
export const ChipTab = (props: ChipTabProps) => {
  const { active = false, fullWidth, ...rest } = props;
  const { classes } = useStyles();

  return (
    <Chip
      variant={active ? 'filled' : 'outlined'}
      color={active ? 'secondary' : 'default'}
      classes={{
        root: clsx(classes.root, { [classes.fullWidth]: fullWidth }),
        outlined: classes.outlined,
        labelMedium: classes.labelMedium,
      }}
      {...rest}
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    '&:hover': {
      background: theme.palette.secondary.main,
    },

    // padding
  },
  outlined: {
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    '&&:hover': {
      background: theme.palette.neutral.white,
      color: theme.palette.secondary.main,
    },
  },
  labelMedium: {
    padding: theme.spacing(0, 3),
  },
  fullWidth: {
    width: '100%',
  },
}));
