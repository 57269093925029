import axios, { AxiosRequestConfig } from 'axios';
import { USER_AUTH_TOKEN } from '../domain/auth/localStorageKeys';
import { defaultClientConfig } from '../query/clientConfig';
import { axiosErrorHandler } from './errorHandling';

const getFile = async (url: string) => {
  try {
    const response = await axios.get(`${defaultClientConfig?.baseUrl}${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(USER_AUTH_TOKEN)}`, // Add token
      },
    });
    return response; // Return the successful response
  } catch (error) {
    return axiosErrorHandler(error);
  }
};

const uploadFormData = async (
  url: string,
  formData: FormData,
  config?: AxiosRequestConfig<FormData>,
) => {
  try {
    const response = await axios.post(
      `${defaultClientConfig?.baseUrl}${url}`, // Combine base URL and endpoint
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Required for form uploads
          Authorization: `Bearer ${localStorage.getItem(USER_AUTH_TOKEN)}`, // Add token
        },
        ...config,
      },
    );
    return response; // Return the successful response
  } catch (error) {
    return axiosErrorHandler(error);
  }
};

export const MyAxios = {
  getFile,
  uploadFormData,
};
