import { useMutation } from 'react-query';
import { authPatchKeygen } from 'src/core/query/fa/authClient';
import { ChipTab } from 'src/shared/components/ChipTab';
import { makeStyles } from 'tss-react/mui';
import { useAuthenticationContext } from '../authenticationTypeContext';
import { BankInformationStage } from '../stages/bankInfoStage/BankInformationStage';
import { ContactInfoStage } from '../stages/contactInfoStage/ContactInfoStage';
import { PersonalInfoStage } from '../stages/personalInfo/PersonalInfoStage';
import { UploadDocumentsStage } from '../stages/uploadDocuments/UploadDocumentsStage';
import { AuthenticationType } from '../types';
import { useAuthStatus } from '../hooks/useAuthStatus';
import { useConfirm } from 'src/shared/contexts/confirm/confirm.context';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
};

const stages = [UploadDocumentsStage, PersonalInfoStage, ContactInfoStage, BankInformationStage];

export const AuthenticationForms = (props: PropsType) => {
  const { classes } = useStyles();

  const confirm = useConfirm();

  const { authenticationType, setAuthenticationType } = useAuthenticationContext();

  const { status } = useAuthStatus();

  const { mutateAsync: changeAuthenticationType, isLoading: isLoadingChangeAuthType } = useMutation(
    authPatchKeygen('/api/v1/auth/user/investor/auth/type'),
  );

  const Stage = stages[props.formStep];

  const accountStatus = status?.account;

  const handleAuthentication = (type: AuthenticationType) => () => {
    if (type === 'instant') return;
    if (authenticationType === 'instant') {
      confirm({
        title:
          'درصورت تغییر روند به  احراز هویت غیرآنی دیگر امکان بازگشت به احراز هویت آنی را ندارید',
      }).then(() => {
        changeAuthenticationType({
          params: { query: { authType: 'notinstant' } },
        }).then(() => setAuthenticationType('notinstant'));
      });
    }
  };

  const headerPayload = !(
    accountStatus === 'active' ||
    accountStatus === 'pending' ||
    accountStatus === 'bank_info_completed'
  ) ? (
    <div className={classes.row}>
      <ChipTab
        onClick={handleAuthentication('instant')}
        active={authenticationType === 'instant'}
        label="احراز هویت آنی"
        disabled={isLoadingChangeAuthType || authenticationType === 'notinstant'}
      />
      <ChipTab
        onClick={handleAuthentication('notinstant')}
        active={authenticationType === 'notinstant'}
        label="احراز هویت غیر آنی"
        disabled={isLoadingChangeAuthType}
      />
    </div>
  ) : null;

  return (
    <div className={classes.container}>
      <Stage
        formStep={props.formStep}
        setFormStep={props.setFormStep}
        disabled={props.disabled}
        headerPayload={headerPayload}
      />
    </div>
  );
};
const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  row: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));
