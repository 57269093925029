import { t } from '@lingui/macro';
import { components } from 'src/types/en/auth';

type FieldType = {
  label: string;
  placeholder: string;
  type: 'text' | 'select' | 'date';
  name: NameType;
};

type NameType = Exclude<
  keyof components['schemas']['datastruct.NotInstantPersonalInfoAuthInputs'],
  'issue_town' | 'father_name' | 'mobile'
>;

export const dataInputs: FieldType[] = [
  {
    label: t`Name`,
    placeholder: t`Enter your name`,
    type: 'text',
    name: 'first_name',
  },
  {
    label: t`Last name`,
    placeholder: t`Enter your last name`,
    type: 'text',
    name: 'last_name',
  },
  {
    label: t`National Code`,
    placeholder: t`Enter your national code`,
    type: 'text',
    name: 'national_id',
  },
  {
    label: t`Gender`,
    placeholder: t`Select the desired option`,
    type: 'select',
    name: 'gender',
  },
  {
    label: t`Date of birth`,
    placeholder: t`Select the desired date`,
    type: 'date',
    name: 'birth_date',
  },
];
