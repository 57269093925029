import { PropsWithChildren, createContext, useCallback, useContext, useRef, useState } from 'react';
import { alpha, Button, Dialog, Typography } from '@mui/material';
import { ConfirmProps } from './type';
import { useBooleanState } from 'src/shared/hooks';
import { makeStyles } from 'tss-react/mui';
import { t } from '@lingui/macro';
import { WarningDarkBackground as WarningIcon } from 'src/shared/icons/WarningDarkBackground';

const DEFAULT: Required<ConfirmProps> = {
  title: t`Are you sure?`,
  description: '',
  confirmButtonText: t`Confirm`,
  rejectButtonText: t`Cancel`,
  confirmButtonPosition: 'left',
};

type ContextValue = (props?: ConfirmProps) => Promise<void>;
const confirmContext = createContext<ContextValue>(() => new Promise(() => {}));

export function ConfirmContextProvider({ children }: PropsWithChildren) {
  const [open, toggle] = useBooleanState();
  const [config, setConfig] = useState<Required<ConfirmProps>>(DEFAULT);
  const resolveRef = useRef<((value: void | PromiseLike<void>) => void) | null>();
  const rejectRef = useRef<((reason?: any) => void) | null>();
  const { classes } = useStyles();

  const confirm = useCallback(
    (props?: ConfirmProps) => {
      setConfig({
        title: props?.title || DEFAULT.title,
        description: props?.description || DEFAULT.description,
        confirmButtonText: props?.confirmButtonText || DEFAULT.confirmButtonText,
        rejectButtonText: props?.rejectButtonText || DEFAULT.rejectButtonText,
        confirmButtonPosition: props?.confirmButtonPosition || DEFAULT.confirmButtonPosition,
      });
      toggle(true);

      return new Promise<void>((resolve, reject) => {
        // storing resolve and reject for later use
        resolveRef.current = resolve;
        rejectRef.current = reject;
      });
    },
    [toggle],
  );

  const handleAccept = () => {
    toggle(false);
    resolveRef.current?.();
  };

  const handleReject = () => {
    toggle(false);
    rejectRef.current?.();
  };

  return (
    <confirmContext.Provider value={confirm}>
      {children}
      <Dialog
        open={!!open}
        onClose={handleReject}
        classes={{ paper: classes.paper }}
        PaperProps={{ elevation: 1 }}
      >
        <div>
          <div className={classes.imageBox}>
            <WarningIcon />
          </div>

          <Typography variant="h4" className={classes.title}>
            {config.title}
          </Typography>

          {config.description && (
            <>
              {typeof config.description !== 'string' ? (
                <div className={classes.description}>{config.description}</div>
              ) : (
                <Typography className={classes.description}>{config.description}</Typography>
              )}
            </>
          )}

          <div className={classes.hr} />

          <div className={classes.buttonsContainer}>
            <Button onClick={handleReject} variant="contained" color="secondary">
              {config.rejectButtonText}
            </Button>
            <Button
              onClick={handleAccept}
              variant="contained"
              style={{ order: config.confirmButtonPosition === 'right' ? -1 : 1 }}
            >
              {config.confirmButtonText}
            </Button>
          </div>
        </div>
      </Dialog>
    </confirmContext.Provider>
  );
}

const useStyles = makeStyles()((theme) => ({
  paper: {
    paddingTop: theme.spacing(3),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 350,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    paddingInline: theme.spacing(4),
    margin: '22px 0 34px',
    textAlign: 'center',
  },
  description: {
    paddingInline: theme.spacing(4),
    margin: '-16px 0 32px',
    textAlign: 'center',
  },
  hr: {
    borderTop: `1px solid ${theme.palette.neutral.lightBlue}`,
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    gap: theme.spacing(1),
  },
}));

export const useConfirm = () => useContext(confirmContext);
