import { Navigate, createBrowserRouter } from 'react-router-dom';
import { DashboardLayout } from 'src/shared/Layout/DashboardLayout';
import { AuthPagesLayout } from 'src/shared/Layout/AuthPagesLayout';
import { RouterError } from 'src/shared/components/RouterError/RouterError';
import UserContextProvider from 'src/shared/contexts/user/user.context';
import WalletContextProvider from 'src/shared/contexts/wallet/wallet.context';
import { AuthGuard } from 'src/shared/components/Guard';
import { lazy } from 'react';
import { LazyLoad } from 'src/shared/components/LazyLoad';
import { AuthenticationPage } from 'src/pages/AuthenticationPage';

const LazyWalletPage = LazyLoad(lazy(() => import('src/pages/Wallet/WalletPage')));
const LazyPaymentResult = LazyLoad(lazy(() => import('src/pages/PaymentResult/PaymentResult')));
const LazyHistory = LazyLoad(lazy(() => import('src/pages/History')));
const LazyMyInvestmentsBasketPage = LazyLoad(
  lazy(() => import('src/pages/MyInvestmentsBasket/MyInvestmentsBasketPage')),
);
const LazyInitialSupplyPage = LazyLoad(
  lazy(() => import('src/pages/InitialSupply/InitialSupplyPage')),
);
const LazyFastBuyingAndSellingPage = LazyLoad(
  lazy(() => import('src/pages/FastBuyingAndSelling/FastBuyingAndSellingPage')),
);

export const router = createBrowserRouter([
  {
    path: '/auth',
    element: (
      <UserContextProvider>
        <AuthPagesLayout />
      </UserContextProvider>
    ),
    children: [
      {
        path: 'forgot-password',
        lazy: () => import('src/pages/ForgotPassword'),
      },
      {
        path: 'login',
        lazy: () => import('src/pages/Login'),
      },
      {
        path: 'sign-up',
        lazy: () => import('src/pages/SignUp'),
      },
    ],
  },
  {
    path: '/',
    element: (
      <UserContextProvider>
        <WalletContextProvider>
          <DashboardLayout />
        </WalletContextProvider>
      </UserContextProvider>
    ),
    hasErrorBoundary: true,
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Navigate to="/investment-opportunities" />,
      },
      {
        path: '/investment-opportunities',
        lazy: () => import('src/pages/InvestmentOpportunities/InvestmentOpportunitiesPage'),
      },
      {
        path: '/investment-opportunities/:id',
        lazy: () => import('src/pages/InvestmentOpportunity/InvestmentOpportunityPage'),
      },
      {
        path: '/my-investments-basket',
        element: (
          <AuthGuard>
            <LazyMyInvestmentsBasketPage />
          </AuthGuard>
        ),
      },
      {
        path: '/wallet',
        element: (
          <AuthGuard>
            <LazyWalletPage />
          </AuthGuard>
        ),
      },
      {
        path: '/wallet/payment',
        element: (
          <AuthGuard>
            <LazyPaymentResult />
          </AuthGuard>
        ),
      },
      {
        path: '/market/initial-supply',
        element: (
          <AuthGuard>
            <LazyInitialSupplyPage />
          </AuthGuard>
        ),
      },
      {
        path: '/market/otc',
        element: (
          <AuthGuard>
            <LazyFastBuyingAndSellingPage />
          </AuthGuard>
        ),
      },
      {
        path: '/history',
        element: (
          <AuthGuard>
            <LazyHistory />
          </AuthGuard>
        ),
      },
      {
        // authentication page is not lazy loaded because it breaks the programmatically-navigation
        // in user.context (probably a bug with react-router-dom)
        path: '/authentication',
        element: <AuthenticationPage />,
      },
      {
        path: '/support',
        lazy: () => import('src/pages/Support/Support'),
      },
      {
        path: '/support/:id',
        lazy: () => import('src/pages/ChatPage/ChatPage'),
      },
      {
        path: '/settings',
        lazy: () => import('src/pages/Settings'),
      },
      {
        path: '/help',
        lazy: () => import('src/pages/Help'),
      },

      {
        path: '/notifications/:id',
        lazy: () => import('src/pages/NotificationsPage/NotificationsPage'),
      },
      {
        path: '/account',
        lazy: () => import('src/pages/AccountPage/AccountPage'),
      },
      {
        path: '/referral',
        lazy: () => import('src/pages/ReferralPage/index'),
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);
