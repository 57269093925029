import createClient from 'openapi-fetch';
import { MutationFunction, UseQueryOptions } from 'react-query';
import { paths } from 'src/types/fa/referral';
import { defaultClientConfig } from '../clientConfig';

export const { GET, POST, PATCH } = createClient<paths>(defaultClientConfig);

export const referralQueryKeygen = <
  URL extends Parameters<typeof GET>[0],
  INIT extends Parameters<typeof GET<URL>>[1],
>(
  url: URL,
  init: INIT,
): UseQueryOptions<Awaited<ReturnType<typeof GET<URL>>>['data']> => {
  return {
    queryKey: [url, init],
  };
};

export const referralPostKeygen =
  <URL extends Parameters<typeof POST>[0]>(
    url: URL,
  ): MutationFunction<Awaited<ReturnType<typeof POST<URL>>>, Parameters<typeof POST<URL>>[1]> =>
  async (variables) => {
    return await POST(url, variables as any);
  };
