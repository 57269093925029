import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, InputAdornment, Typography, alpha } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { authPostKeygen } from 'src/core/query/fa/authClient';
import { Close } from 'src/shared/icons/Close';
import { setCaptionError } from 'src/shared/utils/error-utils';
import { makeStyles } from 'tss-react/mui';
import { FaInstantVerifyBankCodeSchemaType, faInstantVerifyBankCodeSchema } from './schema';
import FormTextField from '../form-fields/form-text-field';

interface PropsType {
  open: boolean;
  onClose: () => void;
  cardNumber: string | undefined;
}

export const InstantBankModal = (props: PropsType) => {
  const { open, onClose } = props;
  const { classes } = useStyles();

  const { mutateAsync: mutateAsyncBank, isLoading: isBankLoading } = useMutation(
    authPostKeygen('/api/v1/auth/user/investor/bank/instant'),
  );

  const sendCardNumber = (cardNumber: string) => () => {
    mutateAsyncBank({
      body: { card_number: cardNumber },
      params: {},
    }).then(({ response: resp, data, error }) => {
      if (resp.status === 400) {
        const errorMsg =
          (error as any).data.account_number ||
          (error as any).data.bank ||
          (error as any).data.card_number;
        setCaptionError({ code: errorMsg }, setError);
      } else if (data) {
        setValue('code', '');
        props.onClose();
      }
    });
  };

  const methods = useForm<FaInstantVerifyBankCodeSchemaType>({
    defaultValues: { code: '' },
    mode: 'onBlur',
    resolver: zodResolver(faInstantVerifyBankCodeSchema()),
  });

  const {
    formState: { isValid },
    setError,
    watch,
    getValues,
    setValue,
    trigger,
  } = methods;

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <FormProvider {...methods}>
        <div className={classes.title}>
          <Typography variant="h4">{t`Add bank account`}</Typography>
        </div>
        <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
        <div className={classes.form}>
          <FormTextField
            required
            name="code"
            type="tel"
            label={t`Card number`}
            placeholder={t`Enter the card number`}
            inputProps={{ maxLength: 16 }}
            onChange={(v) => v && v.toString().length === 16 && trigger('code')}
            endAdornment={
              <InputAdornment position="end">{`${watch('code').length}/16`}</InputAdornment>
            }
          />
        </div>
        <div className={classes.action}>
          <LoadingButton
            variant="contained"
            fullWidth
            disabled={!isValid}
            onClick={sendCardNumber(getValues('code'))}
            loading={isBankLoading}
          >
            {t`Add account`}
          </LoadingButton>
        </div>
      </FormProvider>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    gap: 8,
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 350,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    },
    color: theme.palette.grey[500],
  },
}));
