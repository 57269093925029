import { createSvgIcon } from '@mui/material';

export const ArrowDown = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46967 9.71967C7.17678 10.0126 7.17678 10.4874 7.46967 10.7803L11.4697 14.7803C11.7626 15.0732 12.2374 15.0732 12.5303 14.7803L16.5303 10.7803C16.8232 10.4874 16.8232 10.0126 16.5303 9.71967C16.2374 9.42678 15.7626 9.42678 15.4697 9.71967L12 11.75L8.53033 9.71967C8.23744 9.42678 7.76256 9.42678 7.46967 9.71967Z"
      fill="currentColor"
    />
  </svg>,
  'ArrowDown',
);
