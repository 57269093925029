import { t } from '@lingui/macro';
import { Typography, Button, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { UserInfo } from '../icons/UserInfo';

export const NotCompletedAuthCard = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.content}>
        <UserInfo />
        <Typography
          variant="h3"
          mt={3}
          textAlign="center"
        >{t`Authentication is not completed`}</Typography>
        <Typography variant="body2" textAlign="center" mt={3}>
          {t`To access this resource, your authentication must be completed and confirmed`}
        </Typography>
      </div>

      <div className={classes.action}>
        <Link to="/authentication" style={{ width: '100%' }}>
          <Button variant="contained" fullWidth>
            {t`Authenticate now`}
          </Button>
        </Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    paddingTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 390,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  errorIcon: {
    fontSize: 100,
    color: theme.palette.error.main,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    gap: theme.spacing(1),
  },
}));
