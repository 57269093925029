import { createContext, PropsWithChildren, useContext } from 'react';
import { useQuery } from 'react-query';
import { sharedWalletQueryKeygen } from 'src/core/query/sharedKeygen';
import { isAppLocaleFa } from 'src/locales/utils';
import { InitialValue } from './type';
import { useUser } from '../user/user.context';

const initialValue = {
  allBalance: null,
  accessibleBalance: null,
  refetchWalletInfo: () => {},
  isLoadingWalletInfo: true,
};

const walletContext = createContext<InitialValue>(initialValue);

export default function WalletContextProvider({ children }: PropsWithChildren) {
  const { user } = useUser();

  const {
    data: walletInfo,
    refetch: refetchWalletInfo,
    isLoading: isLoadingWalletInfo,
  } = useQuery({
    ...sharedWalletQueryKeygen('/api/wallet/wallet/{token}', {
      params: {
        path: {
          token: isAppLocaleFa() ? 'irt' : 'cad',
        },
      },
    }),
    enabled: !!user && !!user.status && user?.status?.account === 'active',
  });

  return (
    <walletContext.Provider
      value={{
        allBalance: Number(walletInfo?.balance || 0),
        accessibleBalance: Number(walletInfo?.available_balance),
        refetchWalletInfo,
        isLoadingWalletInfo,
      }}
    >
      {children}
    </walletContext.Provider>
  );
}

export const useWalletContext = () => useContext(walletContext);
