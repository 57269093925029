import { t } from '@lingui/macro';
import { accountNumberRegex, numRegex } from 'src/shared/regex/textRegex';
import { z } from 'zod';

export const faInstantVerifyBankCodeSchema = () =>
  z.object({
    code: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .length(16, t`Enter exactly 16 digits`),
  });
export type FaInstantVerifyBankCodeSchemaType = z.infer<
  ReturnType<typeof faInstantVerifyBankCodeSchema>
>;

export const faNotInstantVerifyBankCodeSchema = () =>
  z.object({
    code: z
      .string()
      .regex(accountNumberRegex, 'لطفا عدد یا - یا . وارد کنید')
      .min(8, t`Enter at least 8 digits`)
      .max(20, t`Enter at most 20 digits`),
    card_number: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .length(16, t`Enter exactly 16 digits`),
    bank_id: z.number({
      invalid_type_error: t`Please enter digits`,
      required_error: t`Field is required`,
    }),
  });

export type FaNotInstantVerifyBankCodeSchemaType = z.infer<
  ReturnType<typeof faNotInstantVerifyBankCodeSchema>
>;

export const enVerifyBankCodeSchema = () =>
  z.object({
    code: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .min(10, t`Enter at least 10 digits`)
      .max(20, t`Enter at most 20 digits`),
    card_number: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .length(16, t`Enter exactly 16 digits`),
    bank_name: z.string().min(1, t`Field is required`),
  });
export type EnVerifyBankCodeSchemaType = z.infer<ReturnType<typeof enVerifyBankCodeSchema>>;
