import { Divider, Typography, alpha } from '@mui/material';
import { useMedia } from 'src/shared/hooks';
import { makeStyles } from 'tss-react/mui';
import { HeaderStage } from './HeaderStage';
import { Trans, t } from '@lingui/macro';

type PropsType = {
  formStep: number;
  lastStep: number;
};
type HeaderStagesType = {
  level: number;
  stage: string;
  name: string;
};

const headerStages: HeaderStagesType[] = [
  {
    level: 0,
    stage: t`First stage`,
    name: t`Upload documents`,
  },
  {
    level: 1,
    stage: t`Second stage`,
    name: t`Personal information`,
  },
  {
    level: 2,
    stage: t`Third stage`,
    name: t`Contact information`,
  },
  {
    level: 3,
    stage: t`Fourth stage`,
    name: t`Bank information`,
  },
];

export const AuthenticationHeader = (props: PropsType) => {
  const { classes } = useStyles();

  const isUpSm = useMedia('sm');

  const currentStage = headerStages[props.formStep > 3 ? 3 : props.formStep];
  return (
    <div className={classes.card}>
      {isUpSm ? (
        headerStages.map((stage, index) => (
          <div className={classes.stageWrapper} key={`${index}+`}>
            <HeaderStage
              level={stage.level}
              lastStep={props.lastStep}
              stage={stage.stage}
              name={stage.name}
            />

            {index !== 3 && (
              <Divider flexItem={true} className={classes.divider} orientation="vertical" />
            )}
          </div>
        ))
      ) : (
        <div className={classes.row}>
          <HeaderStage
            level={currentStage.level}
            lastStep={props.lastStep}
            stage={currentStage.stage}
            name={currentStage.name}
          />
          <Typography variant="body1" color={'neutral.lightGrey'} className={classes.stageCount}>
            <Trans>
              <em>Stage {currentStage.level + 1}</em>
              of 4
            </Trans>
          </Typography>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    maxHeight: 96,
    minHeight: 96,
    width: '100%',
    borderRadius: 16,
    background: `linear-gradient(92deg, ${theme.palette.secondary.dark} 0, ${theme.palette.common.black} 100%)`,
    boxShadow: `0px 24px 48px 0px rgba(16, 137, 255, 0.08)`,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 728,
      paddingInline: theme.spacing(3),
      gap: theme.spacing(3),
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  divider: {
    width: 1.5,
    color: 'green',
    backgroundColor: alpha(theme.palette.neutral.white, 0.12),
  },
  stageCount: {
    '& > em': {
      fontStyle: 'normal',
      marginLeft: theme.spacing(1),
      color: theme.palette.neutral.white,
    },
  },
  stageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(3),
    height: '100%',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingInline: theme.spacing(3),
    alignContent: 'center',
    alignItems: 'center',
  },
  headerStage: {
    // paddingInline: theme.spacing(3),
  },
}));
