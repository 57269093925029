import createClient from 'openapi-fetch';
import { UseQueryOptions } from 'react-query';
import { paths } from 'src/types/fa/notif';
import { defaultClientConfig } from '../clientConfig';
import { ErrorResponse } from '../errorutils';

export const { GET, POST, PATCH } = createClient<paths>(defaultClientConfig);

export const notifQueryKeygen = <
  URL extends Parameters<typeof GET>[0],
  INIT extends Parameters<typeof GET<URL>>[1],
>(
  url: URL,
  init: INIT,
): UseQueryOptions<Awaited<ReturnType<typeof GET<URL>>>['data'], ErrorResponse> => {
  return {
    queryKey: [url, init],
  };
};
