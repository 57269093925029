import { alpha, IconButton, InputLabel, TextFieldProps } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { FieldCommonProps } from './common-props.type';
import { MuiTextInputChangeEvent } from 'src/shared/types/common-props.type';
import { persianNumbersToEnglish } from 'src/shared/utils/persian-numbers-to-english';
import { TextField } from '../textfield';
import { useBooleanState } from 'src/shared/hooks';
import { Eye } from 'src/shared/icons/Eye';
import { isAppLocaleFa } from 'src/locales/utils';

type MuiTextFieldProps = Pick<
  TextFieldProps,
  | 'multiline'
  | 'rows'
  | 'autoFocus'
  | 'onKeyDownCapture'
  | 'inputRef'
  | 'inputProps'
  | 'InputProps'
  | 'autoComplete'
  | 'required'
>;
type CustomProps = {
  onChange?: (value: string | number) => void;
  type?: 'number' | 'text' | 'tel' | 'password' | 'email';
  formatAsPrice?: boolean;
  endAdornment?: React.ReactNode;
  convertFaNumbersToEn?: boolean;
};

const validNumberInputs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const validPhoneInputs = ['+', ...validNumberInputs];

export default function FormTextField<FormObject extends Record<string, any>>(
  props: FieldCommonProps<FormObject> & MuiTextFieldProps & CustomProps,
) {
  const {
    name,
    helperText,
    onChange: onChangeValue,
    label,
    placeholder: _placeholder = '',
    type = 'text',
    formatAsPrice,
    endAdornment,
    inputProps,
    convertFaNumbersToEn,
    ...rest
  } = props;

  const [showPassword, toggleShowPassword] = useBooleanState({ initialValue: false });
  const { control } = useFormContext();

  //TODO do this in styles instead of js
  const excludeLastDot = /^[^.]*\.$/;
  const placeholder = excludeLastDot.test(_placeholder.trim())
    ? _placeholder.trim().slice(0, _placeholder.length - 1)
    : _placeholder.trim();

  const handleChange = (
    e: MuiTextInputChangeEvent,
    formOnChange: (value: string | number) => void,
  ) => {
    if (type === 'text' || type === 'email' || type === 'password') {
      // HINT: converting farsi numbers to english in email inputs is because
      // some inputs in our app accepts both email & phone number
      // so there is a chance that users want to enter their phone number on desktop devices
      const value =
        convertFaNumbersToEn || type === 'email'
          ? persianNumbersToEnglish(e.target.value)
          : e.target.value;
      formOnChange(value);
      onChangeValue?.(value);
    } else {
      const stringValue = persianNumbersToEnglish(e.target.value)
        .split('')
        .filter((letter) =>
          (type === 'tel' ? validPhoneInputs : validNumberInputs).includes(letter),
        )
        .join('');

      const newValue =
        type === 'tel'
          ? stringValue //
          : stringValue // type === 'number'
            ? Number(stringValue)
            : null; // this check will prevent converting "" to 0 if type==='number'

      formOnChange(newValue as string | number);
      onChangeValue?.(newValue as string | number);
    }
  };

  const endAdornmentElement =
    type === 'password' ? (
      <div style={{ paddingRight: isAppLocaleFa() ? 8 : 0, paddingLeft: isAppLocaleFa() ? 0 : 8 }}>
        <IconButton size="small" onClick={() => toggleShowPassword()}>
          <Eye />
        </IconButton>
      </div>
    ) : (
      endAdornment
    );

  return (
    <Controller
      name={name as string}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className="w-full">
          <InputLabel
            error={!!error}
            style={{ marginBottom: 8, color: props.disabled ? alpha('#000', 0.4) : '#0B1226' }}
            disabled={props.disabled}
            required={props.required}
          >
            {label}
          </InputLabel>
          <TextField
            {...field}
            value={
              type === 'number' && formatAsPrice && field.value
                ? Intl.NumberFormat('fa-IR').format(field.value)
                : (field.value ?? '') // allow 0
            }
            onChange={(e) => handleChange(e, field.onChange)}
            error={!!error}
            fullWidth
            placeholder={placeholder || `${label} را وارد کنید`}
            helperText={error ? error?.message : helperText}
            endAdornment={endAdornmentElement}
            inputProps={{
              ...inputProps,
              className: isAppLocaleFa()
                ? type === 'number' || type === 'tel' || type === 'password' || type === 'email'
                  ? 'dir-ltr text-right'
                  : 'dir-rtl text-right'
                : '',
            }}
            type={
              type === 'number' || type === 'tel'
                ? 'tel'
                : (type === 'password' && showPassword) || type === 'email'
                  ? 'text'
                  : type
            }
            {...rest}
          />
        </div>
      )}
    />
  );
}
