import { t } from '@lingui/macro';
import { Helmet } from 'react-helmet';
import { getAppLocale } from 'src/locales/utils';

export const PageHeader = () => {
  return (
    <Helmet>
      <title>{t`Metri Land`}</title>
      <link
        rel="icon"
        type="image/svg+xml"
        href={getAppLocale() === 'en' ? '/tokenision-logo.png' : '/metriland-favicon.ico'}
      />
    </Helmet>
  );
};
