import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Plus } from 'src/shared/icons/Plus';
import { makeStyles } from 'tss-react/mui';

type PropsType = {
  handleOpen: () => void;
};
export const AddCard = (props: PropsType) => {
  const { classes } = useStyles();
  return (
    <div className={classes.defaultCard}>
      <Typography
        onClick={props.handleOpen}
        variant="body3"
        color={'secondary.main'}
        className={classes.delete}
      >
        <Plus />
        {t`Add bank account`}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  defaultCard: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.neutral.lightExtraBlue,
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  delete: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
