export const numRegex = /^[0-9\b]+$/;
export const accountNumberRegex = /^[0-9\-.\b]+$/;
export const stringRegex = /^(?!.*[0-9!@#$%^&*()_+{}":?~><]).*$/;
export const addressRegex = /^(?!.*[!@#$%^&*()_+{}(),'":?~><]).*$/;
export const faPostalCodeRegex = /^[0-9 -_,\b]+$/;
export const enPostalCodeRegex = /^[a-zA-Z0-9]+$/;
export const numberAndAlphabetRegex = /^[a-zA-Z0-9]+$/;
export const emptyOrNumberRegex = /^(\s*|\d+)$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileNumberRegex = /^(0|0098|\+98)9(0[1-5]|[1 3]\d|2[0-2]|9[0-4,6]|98)\d{7}$/;
