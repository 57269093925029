import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import { EmptyState } from 'src/shared/icons/EmptyState';
import { tss } from 'tss-react/mui';

export const RouterError = () => {
  const { classes } = useStyles();
  const error = useRouteError();

  console.error('Error in RouterError: ', error);

  return (
    <div className={classes.root}>
      <EmptyState />
      <Typography variant="h3">
        <Trans>Something went wrong</Trans>
      </Typography>
      <Typography>
        <Trans>Please refresh your browser</Trans>
      </Typography>
    </div>
  );
};

const useStyles = tss.create(({ theme }) => ({
  root: {
    width: '100vw',
    height: '100dvh',
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
