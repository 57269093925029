import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, InputAdornment, Typography, alpha } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorCaption } from 'src/shared/components/ErrorCaption';
import { TextField } from 'src/shared/components/textfield';
import { Close } from 'src/shared/icons/Close';
import { makeStyles } from 'tss-react/mui';
import { VerifyContactCodeSchemaType, verifyContactCodeSchema } from '../../schema';
import { useAuthOTP } from './useAuthOTP';
import { useEffect } from 'react';

interface PropsType {
  open: boolean;
  onClose: () => void;
  identifier: string;
  countryCode?: number;
  sendType: 'contact_info_mobile' | 'contact_info_email';
  verifyType: 'contact_info_sms' | 'contact_info_email';
}

export const ConfirmationModal = (props: PropsType) => {
  const { open, onClose } = props;

  const { classes } = useStyles();

  const {
    isOTPLoading,
    handleRequestOTPCodeOnClick,
    handleVerifyOTPCode,
    OTPState,
    remainingOTPTime,
    maxTime,
  } = useAuthOTP(props);

  useEffect(() => {
    console.log({ OTPState });
    open && OTPState === 'no-code-sent-yet' && handleRequestOTPCodeOnClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<VerifyContactCodeSchemaType>({
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
    resolver: zodResolver(verifyContactCodeSchema()),
  });

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <div className={classes.title}>
        <Typography variant="h4">
          {props.sendType === 'contact_info_mobile'
            ? t`Confirm phone number`
            : t`Confirm email address`}
        </Typography>
      </div>
      <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
      <div className={classes.form}>
        <Typography className={classes.description} variant="body1" color="neutral.darkGrey">
          {props.sendType === 'contact_info_mobile' ? (
            <Trans>
              A 5-digit code was sent to the number{' '}
              <em>
                {props.countryCode}-{props.identifier}
              </em>
              . Enter the code to confirm your number.
            </Trans>
          ) : (
            <Trans>
              A 5-digit code was sent to the email address <em>{props.identifier}</em>. Enter the
              code to confirm your email address.
            </Trans>
          )}
        </Typography>
        <Controller
          name={'code'}
          control={control}
          render={({ field }) => (
            <div>
              <TextField
                value={field.value}
                onChange={field.onChange}
                label={t`Confirmation code`}
                endAdornment={
                  <InputAdornment position="end">{`${watch('code').length}/5`}</InputAdornment>
                }
                placeholder={t`Enter Confirmation code`}
                required
                fullWidth
              />
              <ErrorCaption caption={errors.code?.message} />
            </div>
          )}
        />

        <Typography
          onClick={handleRequestOTPCodeOnClick}
          variant="body3"
          color={OTPState === 'no-code-sent-yet' ? 'secondary' : 'neutral.darkGrey'}
          sx={{
            cursor: OTPState === 'no-code-sent-yet' || !maxTime ? 'pointer' : 'default',
            marginTop: 1,
          }}
        >
          {OTPState === 'no-code-sent-yet' || !maxTime
            ? t`Resend code`
            : t`Resend code after ${remainingOTPTime}`}
        </Typography>
      </div>
      <div className={classes.action}>
        <LoadingButton
          variant="contained"
          fullWidth
          disabled={OTPState === 'no-code-sent-yet' || !watch('code') || !isValid}
          onClick={handleVerifyOTPCode(watch('code'))}
          loading={isOTPLoading}
        >
          {t`Confirm`}
        </LoadingButton>
      </div>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    gap: 8,
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 350,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    },
    color: theme.palette.grey[500],
  },
  description: {
    '& > em': {
      fontStyle: 'normal',
      marginRight: theme.spacing(1),
      color: theme.palette.common.black,
    },
  },
}));
