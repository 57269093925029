import { useMutation, useQuery } from 'react-query';
import {
  authPatchKeygen as faAuthPatchKeygen,
  authQueryKeygen as faAuthQueryKeygen,
} from 'src/core/query/fa/authClient';
import { sharedAuthPatchKeygen } from 'src/core/query/sharedKeygen';
import { useAuthenticationContext } from '../../../authenticationTypeContext';

export const useBankInfo = () => {
  const { authenticationType } = useAuthenticationContext();
  const {
    data: bankInfo,
    refetch: bankInfoRefetch,
    isLoading: isLoadingBankInfo,
  } = useQuery({
    ...faAuthQueryKeygen('/api/v1/auth/user/investor/bank', {
      params: { header: {} },
    }),
  });
  const { mutateAsync: submitAuthForm, isLoading: isLoadingAuthForm } = useMutation(
    sharedAuthPatchKeygen('/api/v1/auth/user/investor/auth/subbmit'),
  );

  const { mutateAsync: deleteInstantCardNumber, isLoading: isLoadingDeleteInstant } = useMutation(
    faAuthPatchKeygen('/api/v1/auth/user/investor/bank/instant'),
  );

  const { mutateAsync: deleteNotInstantInstantCardNumber, isLoading: isLoadingDeleteNotInstant } =
    useMutation(faAuthPatchKeygen('/api/v1/auth/user/investor/bank/notinstant'));

  const isLoadingDeleteCardNumber = isLoadingDeleteInstant || isLoadingDeleteNotInstant;

  const handleDeleteInstant = () => {
    bankInfo?.data?.accounts[0]?.card_number &&
      deleteInstantCardNumber({
        body: { card_number: bankInfo?.data?.accounts[0].card_number },
        params: {},
      }).then(() => bankInfoRefetch());
  };

  const handleDeleteNotInstant = () => {
    bankInfo?.data?.accounts[0]?.card_number &&
      bankInfo?.data?.accounts[0]?.account_number &&
      deleteNotInstantInstantCardNumber({
        body: {
          account_number: bankInfo.data.accounts[0].account_number,
          card_number: bankInfo.data.accounts[0].card_number,
        },
        params: {},
      }).then(() => bankInfoRefetch());
  };

  return {
    bankInfo,
    bankInfoRefetch,
    submitAuthForm,
    isLoadingAuthForm,
    isLoadingDeleteCardNumber,
    isLoadingBankInfo,
    handleDelete: authenticationType === 'instant' ? handleDeleteInstant : handleDeleteNotInstant,
  };
};
