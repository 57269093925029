import { t } from '@lingui/macro';
import { Button, Grid, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AutoComplete } from 'src/shared/components/AutoComplete';
import { ErrorCaption } from 'src/shared/components/ErrorCaption';
import { TextField } from 'src/shared/components/textfield';
import { useDialog } from 'src/shared/hooks';
import { Check } from 'src/shared/icons/Check';
import { setCaptionError } from 'src/shared/utils/error-utils';
import { makeStyles } from 'tss-react/mui';
import { BottomOptions } from '../../components/BottomOptions';
import { ConfirmationModal } from '../../components/confirmationModal/ConfirmationModal';
import { PhoneCodeSelect } from '../../components/PhoneCodeSelect';
import { Header } from './components/Header';
import { useContactInfo } from './hooks/useGetContactInfo';
import { useGetAuthStatus } from '../../hooks/useGetAuthStatus';
import { useSetFormData } from './hooks/useSetFormData';
import { useFormData } from './useFormData';
import { FieldType, fillInputs as putDataIntoArray } from './utils';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
};
export const ContactInfoStage = (props: PropsType) => {
  const { classes } = useStyles();

  const {
    dialog: { open: phoneOpen, onClose: phoneOnClose },
    button: { onClick: onClickPhone },
  } = useDialog(false);

  const {
    dialog: { open: emailOpen, onClose: emailOnClose },
    button: { onClick: onClickEmail },
  } = useDialog(false);

  const { contactInfo, refetchContactInfo, isLoadingSubmittingContactInfo, submitContactInfo } =
    useContactInfo();

  const { data: status } = useGetAuthStatus();

  const {
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useSetFormData(refetchContactInfo);

  const { cityData, countryData, phoneCodes, provinceData } = useFormData({
    countryId: watch('country_id'),
    provinceId: watch('province_id'),
  });

  const { addressInput, dataInputs } = putDataIntoArray({
    cityData,
    contactInfo,
    countryData,
    provinceData,
    reset,
    status,
    watch,
  });

  const handleGoBackToPersonalInformation = () => {
    props.setFormStep((step) => step - 1);
  };
  const handleSubmitForm = () => {
    if (props.disabled) {
      handleGoToBankInfo();
      return;
    }
    const formData = watch();
    submitContactInfo({
      body: {
        address: formData.address,
        city_id: Number(watch('city_id')),
        home_phone: formData.home_phone?.length === 0 ? undefined : formData.home_phone,
        postal_code: formData.postal_code ?? '',
      },
      params: {},
    }).then((resp) => {
      if (resp.error) {
        // @ts-ignore
        setCaptionError(resp.error.data, setError);
      } else if (resp.data) {
        props.setFormStep((step) => step + 1);
      }
    });
  };

  const handleGoToBankInfo = () => {
    props.setFormStep((step) => step + 1);
  };

  const makeEndAdornment = (input: FieldType, onButtonClick: () => void) =>
    input.confirmed ? (
      <Typography variant="caption2" className={classes.row} color="success.main">
        <Check />
        <Typography
          sx={{
            whiteSpace: 'nowrap',
          }}
        >{t`Confirmed`}</Typography>
      </Typography>
    ) : (
      <Button
        onClick={onButtonClick}
        variant="contained"
        size="small"
        disabled={!!errors[input.name] || String(watch(input.name)).length < 1}
      >{t`Confirm`}</Button>
    );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Header />
        <Grid container spacing={2}>
          {dataInputs.map((dataInput, index) => (
            <Grid key={index} item xs={12} sm={6}>
              {dataInput.type === 'text' && dataInput.name !== 'mobile' ? (
                <Controller
                  name={dataInput.name}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <TextField
                        value={field.value}
                        onChange={field.onChange}
                        label={dataInput.label}
                        placeholder={dataInput.placeholder}
                        disabled={dataInput.disable || props.disabled}
                        endAdornment={
                          index <= 1 &&
                          makeEndAdornment(dataInput, index === 1 ? onClickPhone : onClickEmail)
                        }
                        inputProps={
                          dataInput.name === 'postal_code' ? { maxLength: 10 } : undefined
                        }
                        required={!dataInput.optional}
                        fullWidth
                      />
                      <ErrorCaption caption={errors[dataInput.name]?.message} />
                    </div>
                  )}
                />
              ) : dataInput.name === 'mobile' ? (
                <Controller
                  name={dataInput.name}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <PhoneCodeSelect
                        options={phoneCodes}
                        placeholder={dataInput.placeholder}
                        label={dataInput.label}
                        disabled={props.disabled}
                        value={field.value}
                        onChange={field.onChange}
                        endAdornment={
                          index <= 1 &&
                          (dataInput.confirmed ? (
                            <Typography
                              variant="caption2"
                              className={classes.row}
                              color="success.main"
                            >
                              <Check />
                              <Typography
                                sx={{
                                  whiteSpace: 'nowrap',
                                }}
                              >{t`Confirmed`}</Typography>
                            </Typography>
                          ) : (
                            <Button
                              onClick={onClickPhone}
                              variant="contained"
                              size="small"
                              disabled={
                                !!errors[dataInput.name] ||
                                (watch('mobile.number') ?? '').length < 1 ||
                                watch('mobile.country_code') < 1
                              }
                            >{t`Confirm`}</Button>
                          ))
                        }
                      />
                      <ErrorCaption caption={errors[dataInput.name]?.message} />
                    </div>
                  )}
                />
              ) : (
                <Controller
                  name={dataInput.name}
                  control={control}
                  render={({ field }) => {
                    const labelValueOptions = dataInput.values
                      ? dataInput.values.map((obj) => ({
                          label: obj.name,
                          value: obj.id,
                        }))
                      : [];
                    let value = labelValueOptions.find((item) => item.value === field.value);
                    value = value ? value : { label: '', value: 0 };
                    return (
                      <div>
                        <AutoComplete<number, string, false, undefined>
                          options={labelValueOptions}
                          fullWidth
                          required
                          value={value}
                          onChange={(_, value) => {
                            if (value)
                              field.name === 'city_id'
                                ? field.onChange(value.value)
                                : dataInput.onChange?.(Number(value.value));
                          }}
                          placeholder={dataInput.placeholder}
                          label={dataInput.label}
                          disabled={dataInput.disable || props.disabled}
                        />
                        <ErrorCaption caption={errors[dataInput.name]?.message} />
                      </div>
                    );
                  }}
                />
              )}
            </Grid>
          ))}
          <Grid item xs>
            <Controller
              name={addressInput.name}
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    value={field.value}
                    onChange={field.onChange}
                    label={addressInput.label}
                    placeholder={addressInput.placeholder}
                    disabled={addressInput.disable || props.disabled}
                    required
                    multiline
                    rows={2}
                    fullWidth
                  />
                  <ErrorCaption caption={errors[addressInput.name]?.message} />
                </>
              )}
            />
          </Grid>
        </Grid>
        <ConfirmationModal
          open={phoneOpen}
          onClose={phoneOnClose}
          identifier={watch('mobile.number')}
          countryCode={Number(
            (phoneCodes?.data?.country_codes ?? []).find(
              (item) => item.id === watch('mobile.country_code'),
            )?.phone_code,
          )}
          sendType="contact_info_mobile"
          verifyType="contact_info_sms"
        />
        <ConfirmationModal
          open={emailOpen}
          onClose={emailOnClose}
          identifier={watch('email')}
          sendType="contact_info_email"
          verifyType="contact_info_email"
        />
      </div>
      <BottomOptions
        onPreviousClick={handleGoBackToPersonalInformation}
        nextDisabled={!isValid || status?.data?.identifier !== 'full_verified'}
        onNextClick={handleSubmitForm}
        isNextLoading={isLoadingSubmittingContactInfo}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 728,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));
