import { ConditionalRender } from 'src/shared/components/ConditionalRender';
import { makeStyles } from 'tss-react/mui';
import { AuthenticationForms } from './components/AuthenticationForms';
import { AuthenticationHeader } from './components/AuthenticationHeader';
import { useAuthStep } from './hooks/useAuthStep';
import { useGetAuthStatus } from './hooks/useGetAuthStatus';

export const Component = () => {
  const { classes } = useStyles();

  const { data: status, isLoading } = useGetAuthStatus();

  const { formStep, lastStep, setFormStep } = useAuthStep(status?.data?.account);

  return (
    <ConditionalRender empty={!status?.data?.account} loading={isLoading}>
      <div>
        <div className={classes.extraGreyBackground} />
        <div className={classes.content}>
          <AuthenticationHeader lastStep={lastStep} formStep={formStep} />
          <AuthenticationForms
            formStep={formStep}
            setFormStep={setFormStep}
            isActive={status?.data?.account === 'active' || status?.data?.account === 'pending'}
          />
        </div>
      </div>
    </ConditionalRender>
  );
};

const useStyles = makeStyles()((theme) => ({
  extraGreyBackground: {
    width: '100%',
    background: theme.palette.neutral.lightExtraBlue,
    height: 92,
    [theme.breakpoints.up('lg')]: {
      height: 96,
      borderTop: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: -90,
    gap: theme.spacing(4),
    padding: theme.spacing(0, 3, 3, 3),
    marginBottom: 96,
    minHeight: 'calc(100dvh - 262px)', //90 navbar + 80 header + 92 greybackground
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      padding: 0,
      marginTop: -45,
      height: 'calc(100dvh - 262px)', //90 navbar + 80 header + 92 greybackground
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100dvh - 292px)', //112 header + 96 greybackground
    },
  },
}));
