import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { makeStyles } from 'tss-react/mui';

const MyHeader = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.titleContainer}>
      <Typography variant="h3">{t`Contact information`}</Typography>
      <Typography variant="body2" color={'neutral.darkGrey'}>
        {t`Personal information is confirmed if it matches with the national card. This case will be investigated by the admin.`}
      </Typography>
      <Typography variant="body2" color={'neutral.darkGrey'}>
        {t`We are currently experiencing an outage with our SMS provider, which is affecting SMS notifications. As a result, you may not be able to receive SMS messages from our platform. Please continue your authentication process without verifying your mobile phone number.`}
      </Typography>
      <Typography variant="body2" color={'neutral.darkGrey'}>
        {t`We are working diligently to restore SMS functionality as soon as possible. We apologize for any inconvenience this may cause.`}
      </Typography>
    </div>
  );
};
export const Header = memo(MyHeader);

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
}));
