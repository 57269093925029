import { InstantBankModal } from 'src/shared/components/BankModal/InstantBankModal';
import { useDialog } from 'src/shared/hooks';
import { useAuthenticationContext } from '../../../authenticationTypeContext';
import { NotInstantBankModal } from 'src/shared/components/BankModal/NotInstantBankModal';

type PropsType = {
  cardNumber?: string;
  handleOnClose?: () => void;
};
export const useBankDialog = (props: PropsType) => {
  const {
    dialog: { open: bankOpen, onClose: bankOnClose },
    button: { onClick: bankModalOnClick },
  } = useDialog(false);
  const { authenticationType } = useAuthenticationContext();
  const handleOnClose = () => {
    props.handleOnClose?.();
    bankOnClose();
  };

  return {
    data: {
      bankModalOnClick,
    },
    Component:
      authenticationType === 'instant' ? (
        <InstantBankModal open={bankOpen} onClose={handleOnClose} cardNumber={props.cardNumber} />
      ) : (
        <NotInstantBankModal open={bankOpen} onClose={handleOnClose} />
      ),
  };
};
