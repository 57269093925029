import {
  ThemeOptions,
  alpha,
  autocompleteClasses,
  buttonClasses,
  menuClasses,
  outlinedInputClasses,
  popoverClasses,
} from '@mui/material';
import 'src/assets/fonts/fa/fontiran.css';
import 'src/assets/fonts/en/fonten.css';
import 'src/assets/fonts/faNum/fontiran.css';
import { Colors } from './types';
import { createTheme, getColorFromThemeWithColorProps } from './utils';

const defaultOptions: ThemeOptions = {
  shape: {
    borderRadius: 24,
  },
  palette: {
    primary: {
      dark: '#9CF449',
      main: '#BCF449',
    },
    secondary: {
      main: '#1089FF',
      dark: '#001A70',
    },
    success: {
      main: '#259B68',
    },
    warning: {
      main: '#FF800B',
    },
    error: {
      main: '#D63838',
    },
    neutral: {
      white: '#FFFFFF',
      lightExtraBlue: '#F8FAFD',
      main: '#E7EEF9',
      lightBlue: '#E7EEF9',
      dark: '#0B1226',
      lightenDark: '#1E2845',
      lightGrey: '#989FB7',
      darkGrey: '#545F7E',
    },
    darkBlue: {
      main: '#001A70',
    },
    coolGrey: {
      main: '#E7EEF9',
    },
    common: {
      black: '#0B1226',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem', //32px
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem', //24px
      fontWeight: 700,
    },
    lightH2: {
      fontSize: '1.5rem', //24px
      fontWeight: 400,
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.125rem', //18px
    },
    lightH3: {
      fontWeight: 400,
      fontSize: '1.125rem', //18px
    },
    h4: {
      fontWeight: 700,
      fontSize: '1rem', // 16px
      lineHeight: '28px',
    },
    mediumH4: {
      fontWeight: 500,
      fontSize: '1rem', // 16px
    },
    body1: {
      fontWeight: 500,
      fontSize: '0.875rem', // 14px
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem', // 14px
    },
    body3: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
    },
    button: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem', // 12px
    },
    caption2: {
      fontWeight: 500,
      fontSize: '0.75rem', // 12px
    },
    caption3: {
      fontWeight: 700,
      fontSize: '0.75rem', // 12px
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'small' && {
            padding: '6px 24px',
          }),
          ...(ownerState.size === 'medium' && {
            padding: '8px 24px',
          }),
          ...(ownerState.size === 'large' && {
            padding: '12px 32px',
          }),
          ...(ownerState.variant === 'contained' && {
            [`&.${buttonClasses.disabled}`]: {
              background: theme.palette.neutral.lightBlue,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            borderWidth: '1.5px',
            [`&.${buttonClasses.disabled}`]: {
              borderColor: theme.palette.neutral.lightBlue,
            },
          }),

          //outlined grey variant
          ...(ownerState.color === 'coolGrey' &&
            ownerState.variant === 'outlined' && {
              borderColor: theme.palette.coolGrey.main,
              color: theme.palette.common.black,
              '&:hover': {
                borderColor: theme.palette.coolGrey.main,
                background: theme.palette.coolGrey.main,
              },
            }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: 10,
          background: theme.palette.neutral.lightExtraBlue,
          borderColor: theme.palette.neutral.lightBlue,
          [`&.Mui-focused`]: {
            boxShadow: `0px 0px 16px 0px ${alpha(
              getColorFromThemeWithColorProps(theme, ownerState),
              0.14,
            )}`,
            background: theme.palette.neutral.white,
          },
          [`&.Mui-focused.Mui-error`]: {
            boxShadow: `0px 0px 16px 0px ${alpha(
              getColorFromThemeWithColorProps(theme, { color: 'error' }),
              0.14,
            )}`,
            background: theme.palette.neutral.white,
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            background: theme.palette.neutral.lightBlue,
            color: theme.palette.neutral.lightGrey,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${popoverClasses.paper}.${menuClasses.paper}, &.${autocompleteClasses.paper}`]: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.neutral.lightBlue}`,
            boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
            marginTop: 5,
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 8,
        },
      },
    },
  },
};

const rtlThemeOptions: ThemeOptions = {
  direction: 'rtl',
  typography: {
    fontFamily: '"IRANYekanXFaNum", "Roboto", "Helvetica", "Arial", sans-serif',
  },
};
const ltrThemeOptions: ThemeOptions = {
  direction: 'ltr',
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  },
};
export const modernDigitsOptions: ThemeOptions = {
  typography: {
    fontFamily: '"IRANYekanX", "Roboto", "Helvetica", "Arial", sans-serif',
  },
};

const createMetrilandTheme = createTheme(defaultOptions);
export const rtlTheme = createMetrilandTheme(rtlThemeOptions);
export const ltrTheme = createMetrilandTheme(ltrThemeOptions);
export const modernDigitsTheme = createMetrilandTheme(modernDigitsOptions);
declare module '@mui/material/styles' {
  interface PaletteColor extends Colors {}
  interface Palette {
    neutral: {
      white: string;
      lightExtraBlue: string;
      lightBlue: string;
      main: string;
      dark: string;
      lightenDark: string;
      lightGrey: string;
      darkGrey: string;
    };
    darkBlue: {
      main: string;
      light: string;
    };
    coolGrey: {
      main: string;
    };
  }

  interface PaletteOptions {
    neutral?: {
      white: string;
      lightExtraBlue: string;
      lightBlue: string;
      main: string;
      dark: string;
      lightenDark: string;
      lightGrey: string;
      darkGrey: string;
    };

    darkBlue?: {
      main: string;
    };
    coolGrey?: {
      main: string;
    };
  }
  interface TypographyVariants {
    caption2: React.CSSProperties;
    caption3: React.CSSProperties;
    lightH3: React.CSSProperties;
    body3: React.CSSProperties;
    lightH2: React.CSSProperties;
    mediumH4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties;
    caption3?: React.CSSProperties;
    lightH3?: React.CSSProperties;
    body3?: React.CSSProperties;
    lightH2?: React.CSSProperties;
    mediumH4?: React.CSSProperties;
  }
}
declare module '@mui/material' {
  interface TypographyPropsVariantOverrides {
    caption2: true;
    caption3: true;
    lightH3: true;
    body3: true;
    lightH2: true;
    mediumH4: true;
  }
  interface ButtonPropsColorOverrides {
    coolGrey: true;
  }
}
