import { t } from '@lingui/macro';
import { Avatar, Button, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ArrowPrev } from '../BidiIcons/ArrowPrev';
import { useUser } from 'src/shared/contexts/user/user.context';

export const MobileUserInfoBox = ({ onNavigate }: { onNavigate?: () => void }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useUser();
  const accountStatus = user?.status?.account;

  const handleRedirectToAuthentication = () => {
    navigate('/authentication');
    onNavigate?.();
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Avatar sx={{ width: 40 }} src={''} />
        <Typography variant="body1" color="black">
          {user?.first_name ?? t`No name`}
        </Typography>

        <Chip
          size="small"
          label={user?.role === 'Investor' ? t`Investor` : t`Broker`}
          classes={{ root: classes.chip }}
          variant="outlined"
        />
      </div>

      {accountStatus !== 'active' && (
        <Button
          variant="contained"
          color={accountStatus === 'rejected' ? 'warning' : 'secondary'}
          className={classes.action}
          startIcon={<ArrowPrev />}
          onClick={handleRedirectToAuthentication}
        >
          {accountStatus === 'pending'
            ? t`Authentication pending`
            : accountStatus === 'rejected'
              ? t`Authentication rejected`
              : t`Authenticate now`}
        </Button>
      )}
    </div>
  );
};
const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    background: theme.palette.neutral.white,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
  },
  action: {
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    paddingTop: 52,
    marginTop: -47,
  },
  info: {
    width: '100%',
    height: 48,
    position: 'relative',
    zIndex: 2,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    gap: 6,
  },
  chip: {
    background: theme.palette.neutral.lightBlue,
    color: theme.palette.secondary.dark,
  },
}));
