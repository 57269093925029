import { t } from '@lingui/macro';
import { PropsWithChildren } from 'react';
import { toast } from 'react-toastify';
import { MESSAGE_IMAGE_TYPES, messages } from 'src/shared/utils/form-validations';

type Props = {
  className?: string;
  name?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxSizeMb?: number;
};

const DEFAULT_MAX_SIZE_MB =
  Number(import.meta.env.NEXT_PUBLIC_UPLOAD_MAX_SIZE_MB || 5) * 1024 * 1024;

export const UploadButton = (props: PropsWithChildren<Props>) => {
  const { children, className, name, disabled, onChange, maxSizeMb = DEFAULT_MAX_SIZE_MB } = props;
  const maxAllowedSize = maxSizeMb;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size > maxAllowedSize) {
      toast.error(t`The size of the image should be less than 5 MB`);
      return;
    } else if (file && file.type === 'application/pdf') {
      toast.error(messages.file.validExtensions(MESSAGE_IMAGE_TYPES));
      return;
    }
    onChange?.(event);
  };

  return (
    <label className={className}>
      <input
        type="file"
        style={{ display: 'none' }}
        name={name}
        accept=".png,.jpg,.jpeg"
        disabled={disabled}
        onChange={onChangeHandler}
      />
      {children}
    </label>
  );
};
