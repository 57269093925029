import { isAppLocaleFa } from 'src/locales/utils';
import { Component as EnAuthenticationPage } from './EnAuthenticationPage/Authentication';
import { Component as FaAuthenticationPage } from './FaAuthenticationPage/Authentication';
import { AuthenticationTypeProvider } from './FaAuthenticationPage/authenticationTypeContext';

export const AuthenticationPage = () => {
  return isAppLocaleFa() ? (
    <AuthenticationTypeProvider>
      <FaAuthenticationPage />
    </AuthenticationTypeProvider>
  ) : (
    <EnAuthenticationPage />
  );
};
