import { createSvgIcon } from '@mui/material';

export const Upload = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.79785 14.7827C4.12912 14.0947 3.62464 13.2624 3.32263 12.349C3.02062 11.4356 2.929 10.4649 3.0547 9.51052C3.1804 8.55614 3.52013 7.64309 4.04816 6.84051C4.57619 6.03793 5.27867 5.36688 6.10239 4.87819C6.92611 4.3895 7.84947 4.09598 8.80252 4.01986C9.75558 3.94375 10.7133 4.08703 11.6033 4.43887C12.4932 4.7907 13.2919 5.34186 13.939 6.05059C14.586 6.75932 15.0644 7.60704 15.338 8.52953H16.9491C17.8182 8.52943 18.6642 8.81081 19.3623 9.33209C20.0603 9.85337 20.5734 10.5869 20.8256 11.4244C21.0779 12.2618 21.056 13.1588 20.7631 13.9827C20.4703 14.8067 19.922 15.5139 19.1994 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 12V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 15L12 12L9 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Upload',
);
