import { t } from '@lingui/macro';
import { Toolbar, ToolbarProps, Typography } from '@mui/material';
import { getAppLocale } from 'src/locales/utils';
import { tss } from 'tss-react/mui';
import { LandingUrl } from 'src/core/domain/LandingUrls';

export const Logo = (props: ToolbarProps) => {
  const { classes } = useStyles();

  const faLogo = <img src="/metriland-logo.png" alt="metriland" className={classes.faLogo} />;

  const enLogo = (
    <>
      <img src="/tokenision-logo.png" alt="tokenision" className={classes.enLogo} />
      <Typography sx={{ marginLeft: 2 }} variant="h2" color="inherit">
        {t`Metri Land`}
      </Typography>
    </>
  );

  return (
    <a href={LandingUrl}>
      <Toolbar className={classes.root} {...props}>
        {getAppLocale() === 'en' ? enLogo : faLogo}
      </Toolbar>
    </a>
  );
};

const useStyles = tss.create(({ theme }) => ({
  root: {
    cursor: 'pointer',
  },
  enLogo: {
    width: 32,
    height: 32,
  },
  faLogo: {
    width: 128,
    height: 45,
    [theme.breakpoints.up('md')]: {
      width: 168,
      height: 60,
    },
  },
}));
