import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type PropsType = {
  caption?: string;
};
export const ErrorCaption = (props: PropsType) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {props.caption && (
        <Typography color={'error.main'} variant="caption">
          {props.caption}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    height: 15,
    marginTop: theme.spacing(0.5),
  },
}));
