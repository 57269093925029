import { Outlet } from 'react-router-dom';
import { tss } from 'tss-react/mui';
import { Logo } from './Logo';
import { Button } from '@mui/material';
import { Trans } from '@lingui/macro';
import { useMedia } from '../hooks';
import { LandingUrl } from 'src/core/domain/LandingUrls';

export const AuthPagesLayout = () => {
  const { classes } = useStyles();
  const isUpSm = useMedia('sm');

  return (
    <div className={classes.root}>
      <div className={classes.darkBackground} />

      <header className={classes.header}>
        <Logo />

        <a href={LandingUrl}>
          <Button
            variant={isUpSm ? 'contained' : 'outlined'}
            color="primary"
            className={classes.button}
          >
            <Trans>Return to main page</Trans>
          </Button>
        </a>
      </header>
      <main id="main">
        <div className={classes.content}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

const useStyles = tss.create(({ theme }) => ({
  root: {
    position: 'relative',
  },
  darkBackground: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 264,
    zIndex: -1,
    background: theme.palette.neutral.dark,
    [theme.breakpoints.up('md')]: {
      height: 364,
    },
  },
  header: {
    width: '100%',
    height: 90,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInlineStart: 16,
    paddingInlineEnd: 18,
    borderBottom: '1px solid #17203a',
    [theme.breakpoints.up('md')]: {
      height: 124,
      paddingInlineEnd: 38,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(6),
    paddingInline: theme.spacing(2),
    paddingTop: 48,
  },
  button: {
    paddingInline: 12,
    [theme.breakpoints.up(430)]: {
      paddingInline: 24,
    },
  },
}));
