import { FieldValues, UseFormSetError } from 'react-hook-form';

export const setCaptionError = <T extends FieldValues>(
  data: object,
  setError: UseFormSetError<T>,
) => {
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      //@ts-ignore
      setError(key as any, { message: value });
    }
  });
};
