import { t } from '@lingui/macro';
import {
  AutocompleteProps,
  CircularProgress,
  Divider,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  InputProps,
  ListItemIcon,
  ListItemIconProps,
  MenuItem,
  MenuItemProps,
  Autocomplete as MuiAutoComplete,
  TextField,
  TextFieldProps,
  Typography,
  buttonBaseClasses,
  formLabelClasses,
} from '@mui/material';
import clsx from 'clsx';
import { omit } from 'ramda';
import React from 'react';
import { ArrowDown } from 'src/shared/icons/ArrowDown';
import { Check } from 'src/shared/icons/Check';
import { ValueLabelList, ValueLabelPair } from 'src/shared/types';
import { makeStyles } from 'tss-react/mui';

interface OwnProps<Value extends string | number, Label extends React.ReactNode>
  extends Pick<InputProps, 'fullWidth' | 'disabled' | 'size' | 'startAdornment' | 'placeholder'> {
  label?: string;
  helperText?: string;
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  formHelperTextProps?: FormHelperTextProps;
  menuItemProps?: MenuItemProps;
  listItemIconProps?: ListItemIconProps;
  selectIndicator?: React.ReactElement;
  options: ValueLabelList<Value, Label>;
  showAll?: boolean;
  error?: boolean;
  color?: TextFieldProps['color'];
  required?: boolean;
}

type Props<
  Value extends string | number,
  Label extends React.ReactNode,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = Omit<
  AutocompleteProps<ValueLabelPair<Value, Label>, Multiple, DisableClearable, false>,
  'options' | 'renderInput'
> &
  OwnProps<Value, Label>;

export const AutoComplete = <
  Value extends string | number,
  Label extends string,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>(
  props: Props<Value, Label, Multiple, DisableClearable>,
) => {
  const {
    label,
    disabled,
    helperText,
    error,
    formHelperTextProps,
    fullWidth,
    formLabelProps,
    formControlProps,
    menuItemProps,
    options: optionsProp,
    selectIndicator = <Check />,
    listItemIconProps,
    showAll,
    popupIcon = <ArrowDown />,
    required,
    color = 'secondary',
    loading,

    ...rest
  } = props;
  const { classes } = useStyles();

  const showAllOption = { value: '', label: t`All` };
  const options = (showAll ? [showAllOption, ...optionsProp] : optionsProp) as ValueLabelList<
    Value,
    Label
  >;

  return (
    <FormControl disabled={disabled} fullWidth={fullWidth} {...formControlProps}>
      {label && (
        <FormLabel
          required={required}
          classes={{ root: classes.formLabel }}
          sx={{ mb: 1, ...formLabelProps?.sx }}
        >
          {label}
        </FormLabel>
      )}

      <MuiAutoComplete
        {...rest}
        noOptionsText={t`No option`}
        popupIcon={popupIcon}
        options={options}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            color={color}
            error={error}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            placeholder={rest.placeholder}
          />
        )}
        disabled={disabled}
        getOptionLabel={(option) => (option.value ? option.label : '')}
        renderOption={(props, { label, value }) =>
          loading ? (
            <MenuItem>{t`Loading ...`}</MenuItem>
          ) : (
            <React.Fragment key={label}>
              <MenuItem
                {...omit(['className'], props)}
                classes={{
                  root: clsx(classes.menuItem, {
                    [classes.menuItemSelected]:
                      value === ((rest.value as ValueLabelPair<Value, Label>)?.value as string),
                  }),
                }}
                {...menuItemProps}
                value={value}
                key={label}
              >
                {typeof label === 'string' || typeof label === 'number' ? (
                  <Typography whiteSpace={'break-spaces'}>{label}</Typography>
                ) : (
                  label
                )}
                {selectIndicator &&
                  value === (rest.value as ValueLabelPair<Value, Label>)?.value && (
                    <ListItemIcon classes={{ root: classes.listItemIcon }} {...listItemIconProps}>
                      {selectIndicator}
                    </ListItemIcon>
                  )}
              </MenuItem>
              <Divider classes={{ root: classes.divider }} />
            </React.Fragment>
          )
        }
      />

      {helperText && (
        <FormHelperText error={error} {...formHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
const useStyles = makeStyles()((theme) => ({
  formLabel: {
    color: theme.palette.common.black,
    [`&.${formLabelClasses.focused}`]: {
      color: theme.palette.common.black,
    },
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.secondary.light,
  },
  menuItemSelected: {
    [`&.${buttonBaseClasses.root}`]: {
      background: 'transparent',
      color: theme.palette.secondary.light,
    },
  },
  menuItem: {
    ['&&']: {
      justifyContent: 'space-between',
    },
  },
  divider: {
    marginLeft: 8,
    marginRight: 8,
    borderColor: theme.palette.neutral.lightBlue,
    '&:last-child': {
      display: 'none',
    },
  },
}));
