import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { ReactNode, memo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAuthenticationContext } from '../../../authenticationTypeContext';

type PropsType = {
  children?: ReactNode;
};
const MyHeader = (props: PropsType) => {
  const { classes } = useStyles();
  const { authenticationType } = useAuthenticationContext();
  return (
    <div className={classes.titleContainer}>
      <div className={classes.actionBar}>
        <Typography variant="h3">{t`Contact information`}</Typography>
        {props.children}
      </div>
      {authenticationType === 'instant' ? (
        <Typography variant="body2" color={'neutral.darkGrey'}>
          اطلاعات محل سکونت، کد پستی و شماره تلفن خود را در این بخش وارد کنید. نشانی شما به صورت
          خودکار و بر اساس کد پستی درج خواهد شد. در صورت مغایرت لطفا در بخش پشتیبانی تیکت بگذارید.{' '}
        </Typography>
      ) : (
        <Typography variant="body2" color={'neutral.darkGrey'}>
          اطلاعات محل سکونت شامل نشانی، کد پستی و شماره تلفن خود را در این بخش وارد کنید.{' '}
        </Typography>
      )}
    </div>
  );
};
export const Header = memo(MyHeader);

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
}));
