import { Typography, alpha } from '@mui/material';
import { Check } from 'src/shared/icons/Check';
import { Loader } from 'src/shared/icons/Loader';
import { makeStyles } from 'tss-react/mui';

type PropsType = {
  lastStep: number;
  stage: string;
  name: string;
  level: number;
};
export const HeaderStage = (props: PropsType) => {
  const { classes } = useStyles();

  const nameColor =
    props.lastStep >= props.level
      ? props.lastStep === props.level
        ? 'primary'
        : 'neutral.lightGrey'
      : 'neutral.white';

  return (
    <div className={classes.row}>
      <div className={classes.icon}>
        {props.lastStep === props.level ? (
          <div className={classes.greenBorder}>
            <Loader color="primary" />
          </div>
        ) : props.lastStep < props.level ? (
          <div className={classes.circle} />
        ) : (
          <div className={classes.greenCircle}>
            <Check />
          </div>
        )}
      </div>
      <div className={classes.column}>
        <Typography
          variant="caption2"
          color={props.lastStep === props.level ? 'neutral.white' : 'neutral.lightGrey'}
        >
          {props.stage}
        </Typography>
        <Typography variant="body3" color={nameColor}>
          {props.name}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  row: {
    display: 'flex',
    gap: theme.spacing(2),
    flexGrow: 1,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    alignSelf: 'center',
  },
  greenBorder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 27,
    height: 27,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  circle: {
    width: 27,
    height: 27,
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.neutral.white, 0.12),
  },
  greenCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.neutral.white,
    width: 27,
    height: 27,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
  },
}));
