import createClient from 'openapi-fetch';
import { MutationFunction, UseQueryOptions } from 'react-query';
import { paths } from 'src/types/fa/support';
import { defaultClientConfig } from '../clientConfig';

export const { GET, POST, PATCH } = createClient<paths>(defaultClientConfig);

export const supportQueryKeygen = <
  URL extends Parameters<typeof GET>[0],
  INIT extends Parameters<typeof GET<URL>>[1],
>(
  url: URL,
  init: INIT,
): UseQueryOptions<Awaited<ReturnType<typeof GET<URL>>>['data']> => {
  return {
    queryKey: [url, init],
  };
};

export const supportPatchKeygen =
  <URL extends Parameters<typeof PATCH>[0]>(
    url: URL,
  ): MutationFunction<
    Awaited<ReturnType<typeof PATCH<URL>>>['data'],
    Parameters<typeof PATCH<URL>>[1]
  > =>
  async (variables) => {
    return (await PATCH(url, variables as any)).data;
  };

export const supportPostKeygen =
  <URL extends Parameters<typeof POST>[0]>(
    url: URL,
  ): MutationFunction<
    Awaited<ReturnType<typeof POST<URL>>>['data'],
    Parameters<typeof POST<URL>>[1]
  > =>
  async (variables) => {
    return (await POST(url, variables as any)).data;
  };
