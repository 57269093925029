import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { isAppLocaleFa } from 'src/locales/utils';

export const DatePicker = (props: DatePickerProps<any>) => {
  const adapter = isAppLocaleFa() ? AdapterDateFnsJalali : AdapterDateFns;
  return (
    <LocalizationProvider dateAdapter={adapter}>
      <MuiDatePicker {...props} />
    </LocalizationProvider>
  );
};
