import { t } from '@lingui/macro';
import { CircularProgress, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

type OwnProps = {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  type?: 'text' | 'logo';
};

export const Loading = (props: OwnProps) => {
  const { classes } = useStyles({
    classesOverrides: props.classes,
  });

  if (props.type === 'logo') {
    return (
      <div className="loader-overlay">
        <div className="loader-container">
          <div className="loader-image-box">
            <div className="loader-logo-wrapper">
              <img src="/metriland-logo.png" alt="metriland" className="loader-image" />
            </div>
          </div>
          <div className="loader-indicator"></div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
      <Typography>{t`Fetching information...`}</Typography>
    </div>
  );
};
const useStyles = tss.create(({ theme }) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
