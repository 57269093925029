import { yupResolver } from '@hookform/resolvers/yup';
import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, InputAdornment, Typography, alpha } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConditionalRender } from 'src/shared/components/ConditionalRender';
import FormTextField from 'src/shared/components/form-fields/form-text-field';
import { Close } from 'src/shared/icons/Close';
import { makeStyles } from 'tss-react/mui';
import { VerifyOTPCodeSchemaType, verifyOTPCodeSchema } from './schema';
import { ResponseType, useOTP } from './useOTP';

interface PropsType {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  lsKey: string;
  requestOtpResponse: () => Promise<ResponseType>;
  verifyOTP: (code: number, nuance: number) => void;
  ui: {
    title: string;
    description: string | ReactNode;
  };
}

export const OTPModal = (props: PropsType) => {
  const { open, onClose } = props;

  const { classes } = useStyles();

  const {
    handleVerifyOTPCode,
    OTPState,
    remainingOTPTime,
    maxTime,
    handleRequestOTPCode,
    isLoadingOTP,
  } = useOTP({
    lsKey: props.lsKey,
    onClose: props.onClose,
    verifyOTP: props.verifyOTP,
    requestOtpResponse: props.requestOtpResponse,
  });

  useEffect(() => {
    open && handleRequestOTPCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const methods = useForm<VerifyOTPCodeSchemaType>({
    mode: 'onBlur',
    resolver: yupResolver(verifyOTPCodeSchema),
    defaultValues: { code: '' },
  });

  const {
    formState: { isValid },
    watch,
    trigger,
    setValue,
  } = methods;

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <div className={classes.title}>
        <Typography variant="h4">{props.ui.title}</Typography>
      </div>
      <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
      <div className={classes.form}>
        <ConditionalRender loading={isLoadingOTP}>
          <Typography className={classes.description} variant="body1" color="neutral.darkGrey">
            {props.ui.description}
          </Typography>
          <FormProvider {...methods}>
            <FormTextField
              name="code"
              label={t`Confirmation code`}
              type="tel"
              placeholder={t`Enter Confirmation code`}
              onChange={(v) => v && v.toString().length === 5 && trigger('code')}
              endAdornment={
                <InputAdornment position="end">{`${watch('code')?.length || 0}/5`}</InputAdornment>
              }
            />
          </FormProvider>

          <Typography
            onClick={
              OTPState === 'no-code-sent-yet' || !maxTime
                ? () => {
                    handleRequestOTPCode();
                    setValue('code', '');
                  }
                : undefined
            }
            variant="body3"
            color={OTPState === 'no-code-sent-yet' ? 'secondary' : 'neutral.darkGrey'}
            sx={{
              cursor: OTPState === 'no-code-sent-yet' || !maxTime ? 'pointer' : 'default',
              marginTop: 1,
            }}
          >
            {OTPState === 'no-code-sent-yet' || !maxTime
              ? t`Resend code`
              : t`Resend code after ${remainingOTPTime} seconds`}
          </Typography>
        </ConditionalRender>
      </div>
      <div className={classes.action}>
        <LoadingButton
          variant="contained"
          fullWidth
          disabled={OTPState === 'no-code-sent-yet' || !watch('code') || !isValid}
          onClick={handleVerifyOTPCode(Number(watch('code')))}
          loading={props.isLoading}
        >
          {t`Confirm`}
        </LoadingButton>
      </div>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    gap: 8,
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 350,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    },
    color: theme.palette.grey[500],
  },
  description: {
    '& > em': {
      fontStyle: 'normal',
      marginRight: theme.spacing(1),
      color: theme.palette.common.black,
    },
  },
}));
