import { ToastContainerProps } from 'react-toastify';
import { getAppDirection } from '../theme/getAppDirection';

export const toastConfig: ToastContainerProps = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: getAppDirection() === 'rtl',
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'colored',
};
