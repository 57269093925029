import { format } from 'date-fns';
import { format as jalaaliFormat } from 'date-fns-jalali';
import { getAppLocale } from 'src/locales/utils';

type DateType = string | number | Date | null;

export const formatDate = (date?: DateType, locale: 'fa' | 'en' = getAppLocale()) => {
  if (!date) {
    return '';
  }
  return locale === 'fa'
    ? jalaaliFormat(new Date(date), 'yyyy/MM/dd')
    : format(new Date(date), 'yyyy-MM-dd');
};

export const formatDateTime = (date?: DateType, locale: 'fa' | 'en' = getAppLocale()) => {
  if (!date) {
    return '';
  }
  return locale === 'fa'
    ? jalaaliFormat(new Date(date), 'HH:mm - yyyy/MM/dd')
    : format(new Date(date), 'HH:mm - yyyy-MM-dd');
};

export const formatChatDate = (date?: DateType, locale: 'fa' | 'en' = getAppLocale()) => {
  if (!date) {
    return '';
  }
  return locale === 'fa'
    ? jalaaliFormat(new Date(date), "dd MMMM yyyy 'ساعت' HH:mm")
    : format(new Date(date), "dd MMMM yyyy 'at' HH:mm");
};
