import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { AutoComplete } from 'src/shared/components/AutoComplete';
import { ErrorCaption } from 'src/shared/components/ErrorCaption';
import FormTextField from 'src/shared/components/form-fields/form-text-field';
import { setCaptionError } from 'src/shared/utils/error-utils';
import { makeStyles } from 'tss-react/mui';
import { useAuthenticationContext } from '../../authenticationTypeContext';
import { BottomOptions } from '../../components/BottomOptions';
import { Header } from './components/Header';
import { useContactInfo } from './hooks/useGetContactInfo';
import { usePostalCodeAddress } from './hooks/usePostalCodeAddress';
import { useSetFormData } from './hooks/useSetFormData';
import { useFormData } from './useFormData';
import { fillInputs as putDataIntoArray } from './utils';
import { useAuthStatus } from '../../hooks/useAuthStatus';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  headerPayload?: ReactNode;
};
export const ContactInfoStage = (props: PropsType) => {
  const { classes } = useStyles();

  const { authenticationType } = useAuthenticationContext();
  
  const {
    refetchContactInfo,
    isLoadingSubmittingContactInfo,
    submitInstantContactInfo,
    submitNotInstantContactInfo,
  } = useContactInfo();

  const { refetch: refetchStatus } = useAuthStatus();

  const methods = useSetFormData(refetchContactInfo);

  const {
    watch,
    reset,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = methods;

  const { cityData, provinceData } = useFormData({
    countryId: watch('country_id'),
    provinceId: watch('province_id'),
  });

  const { addressInput, dataInputs } = putDataIntoArray({
    cityData,
    provinceData,
    authenticationType,
    provinceOnChanged: (value: number) => {
      reset({ ...watch(), province_id: value, city_id: undefined });
    },
  });

  const { refetch: refetchAddress, isFetching: isFetchingAddress } = usePostalCodeAddress({
    postalCode: String(watch('postal_code')),
    onAddressSuccessfullyFetched: (address: string) => setValue('address', address),
    setError,
  });

  const handleGoBackToPersonalInformation = () => {
    props.setFormStep((step) => step - 1);
  };
  const handleSubmitForm = async () => {
    if (props.disabled) {
      handleGoToBankInfo();
      return;
    }
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const formData = watch();
    const response =
      authenticationType === 'instant'
        ? await submitInstantContactInfo({
            body: {
              city_id: Number(formData.city_id),
              home_phone: formData.home_phone?.length === 0 ? undefined : formData.home_phone,
              postal_code: String(formData.postal_code) ?? '',
            },
            params: {},
          })
        : await submitNotInstantContactInfo({
            body: {
              address: formData.address,
              city_id: Number(formData.city_id),
              home_phone: formData.home_phone?.length === 0 ? undefined : formData.home_phone,
              postal_code: String(formData.postal_code) ?? '',
            },
            params: {},
          });

    if (response.error) {
      setCaptionError((response as any).error.data, setError);
    } else if (response.data) {
      refetchStatus().then(() => props.setFormStep((step) => step + 1));
    }
  };

  const handleGoToBankInfo = () => {
    props.setFormStep((step) => step + 1);
  };

  return (
    <FormProvider {...methods}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Header>{props.headerPayload}</Header>
          <Grid container spacing={2}>
            {dataInputs.map((dataInput, index) => (
              <Grid key={index} item xs={12} sm={6}>
                {dataInput.type === 'text' ? (
                  <FormTextField
                    name={dataInput.name}
                    required={!dataInput.optional}
                    label={dataInput.label}
                    placeholder={dataInput.placeholder}
                    disabled={dataInput.disable || props.disabled}
                  />
                ) : dataInput.type === 'number' || dataInput.type === 'tel' ? (
                  <FormTextField
                    type={dataInput.type}
                    name={dataInput.name}
                    required={!dataInput.optional}
                    label={dataInput.label}
                    placeholder={dataInput.placeholder}
                    disabled={dataInput.disable || props.disabled}
                    inputProps={dataInput.name === 'postal_code' ? { maxLength: 10 } : undefined}
                    endAdornment={
                      authenticationType === 'instant' &&
                      dataInput.name === 'postal_code' && (
                        <LoadingButton
                          loading={isFetchingAddress}
                          onClick={() => refetchAddress()}
                          variant="contained"
                          size="small"
                          disabled={
                            !!errors['postal_code'] || String(watch('postal_code')).length < 1
                          }
                        >{t`Confirm`}</LoadingButton>
                      )
                    }
                  />
                ) : (
                  <Controller
                    name={dataInput.name}
                    control={control}
                    render={({ field }) => {
                      const labelValueOptions = dataInput.values
                        ? dataInput.values.map((obj) => ({
                            label: obj.name,
                            value: obj.id,
                          }))
                        : [];
                      let value = labelValueOptions.find((item) => item.value === field.value);
                      value = value ? value : { label: '', value: 0 };
                      return (
                        <div>
                          <AutoComplete<number, string, false, undefined>
                            options={labelValueOptions
                              .slice()
                              .sort((a, b) => a.label.localeCompare(b.label))}
                            fullWidth
                            required
                            value={value}
                            onChange={(_, value) => {
                              if (value)
                                field.name === 'city_id'
                                  ? field.onChange(value.value)
                                  : dataInput.onChange?.(Number(value.value));
                            }}
                            placeholder={dataInput.placeholder}
                            label={dataInput.label}
                            disabled={dataInput.disable || props.disabled}
                          />
                          <ErrorCaption caption={errors[dataInput.name]?.message} />
                        </div>
                      );
                    }}
                  />
                )}
              </Grid>
            ))}
            <Grid item xs>
              <FormTextField
                name={addressInput.name}
                label={addressInput.label}
                placeholder={addressInput.placeholder}
                disabled={addressInput.disable || props.disabled}
                multiline
                rows={2}
                required={authenticationType === 'notinstant'}
              />
            </Grid>
          </Grid>
        </div>
        <BottomOptions
          onPreviousClick={handleGoBackToPersonalInformation}
          // nextDisabled={!isValid} this had a problem with validation
          // so instead we check validation inside submit function
          nextDisabled={false}
          onNextClick={handleSubmitForm}
          isNextLoading={isLoadingSubmittingContactInfo}
        />
      </div>
    </FormProvider>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 728,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));
