import { t } from '@lingui/macro';
import { SuccessModal } from 'src/shared/components/SuccessModal';
import { useDialog } from 'src/shared/hooks';
import { useAuthenticationContext } from '../../../authenticationTypeContext';
import { useNavigate } from 'react-router-dom';

export const useSuccessDialog = () => {
  const navigate = useNavigate();
  const {
    dialog: { open: successOpen, onClose: successOnClose },
    button: { onClick: successModalOnClick },
  } = useDialog(false);

  const { authenticationType } = useAuthenticationContext();

  return {
    data: {
      successModalOnClick,
    },
    Component: (
      <SuccessModal
        open={successOpen}
        onClose={() => {
          successOnClose();
          authenticationType === 'instant' && navigate('/investment-opportunities');
        }}
        title={authenticationType === 'instant' ? 'احراز هویت موفق' : 'ثبت موفقیت‌آمیز'}
        body={
          authenticationType === 'instant'
            ? 'احراز هویت شما با موفقیت انجام شد. اکنون میتوانید از سرویس های سایت استفاده کنید.'
            : 'اطلاعات شما با موفقیت ثبت شد. احراز هویت شما توسط تیم پشتیبانی ما در دست بررسی است و نتیحه حداکثر ظرف 2 روز کاری آینده به اطلاع شما خواهد رسید.'
        }
        buttonCaption={t`Done`}
      />
    ),
  };
};
