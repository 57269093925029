import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { USER_AUTH_TOKEN, USER_ROLE } from '../../domain/auth/localStorageKeys';
import { LandingUrl } from 'src/core/domain/LandingUrls';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET } from 'src/core/query/fa/authClient';
import { InitialValue, UserInfo } from './type';
import useFirstRenderEffect from '../../hooks/use-first-render-effect';

const initialValue: InitialValue = {
  user: null,
  isPending: true,
  isRevalidating: true,
  fetchUserInfo: (() => {}) as any,
  logout: () => {},
};

const userContext = createContext<InitialValue>(initialValue);

export default function UserContextProvider({ children }: PropsWithChildren) {
  const [isPending, setIsPending] = useState(true);
  const [isRevalidating, setIsRevalidating] = useState(false);
  const [user, setUser] = useState<UserInfo | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem(USER_AUTH_TOKEN);

  const fetchUserInfo = useCallback(() => {
    return new Promise<UserInfo>((resolve) => {
      if (!user) {
        setIsPending(true);
      } else {
        setIsRevalidating(true);
      }

      Promise.all([
        GET('/api/v1/auth/user/investor/info/personal'),
        GET('/api/v1/auth/user/investor/auth/status'),
      ])
        .then(([userRes, userStatusRes]) => {
          const user = {
            ...(userRes.data!.data as UserInfo),
            status: userStatusRes.data!.data,
            // TODO remove hardcoded role and read from api response or jwt token
            role: 'Investor' as const,
          };
          if (
            user.status?.account !== 'active' &&
            user.status?.account !== 'pending' &&
            !location.pathname.includes('authentication')
          ) {
            setTimeout(() => {
              navigate('/authentication');
              console.log('navigation completed');
            }, 40);
          }

          setTimeout(() => {
            user && setUser(user);
            setIsPending(false);
            setIsRevalidating(false);
            resolve(user);
          }, 80);
        })
        .catch(() => {
          navigate('/auth/login');
          setIsPending(false);
          setIsRevalidating(false);
        });
    });
  }, [location.pathname, navigate, user]);

  useFirstRenderEffect(() => {
    if (!token) {
      !location.pathname.startsWith('/auth') && navigate('/auth/login');
    } else {
      fetchUserInfo();
    }
  });

  const logout = useCallback(() => {
    localStorage.removeItem(USER_AUTH_TOKEN);
    localStorage.removeItem(USER_ROLE);
    window.location.href = LandingUrl;
  }, []);

  return (
    <userContext.Provider
      value={{
        user,
        fetchUserInfo,
        isPending,
        isRevalidating,
        logout,
      }}
    >
      {children}
    </userContext.Provider>
  );
}

export const useUser = () => useContext(userContext);
