import { useMutation, useQuery } from 'react-query';
import { authPostKeygen, authQueryKeygen } from 'src/core/query/fa/authClient';

export const useContactInfo = () => {
  const { refetch: refetchContactInfo } = useQuery({
    ...authQueryKeygen('/api/v1/auth/user/investor/info/contact', {
      params: { header: {} },
    }),
  });

  const {
    mutateAsync: submitInstantContactInfo,
    isLoading: isLoadingSubmittingInstantContactInfo,
  } = useMutation(authPostKeygen('/api/v1/auth/user/investor/auth/instant/contactinfo'));

  const {
    mutateAsync: submitNotInstantContactInfo,
    isLoading: isLoadingSubmittingNotInstantContactInfo,
  } = useMutation(authPostKeygen('/api/v1/auth/user/investor/auth/notinstant/contactinfo'));

  const isLoadingSubmittingContactInfo =
    isLoadingSubmittingInstantContactInfo || isLoadingSubmittingNotInstantContactInfo;
  return {
    refetchContactInfo,
    submitInstantContactInfo,
    submitNotInstantContactInfo,
    isLoadingSubmittingContactInfo,
  };
};
