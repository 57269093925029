import { t } from '@lingui/macro';
import { CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Plus } from 'src/shared/icons/Plus';
import { Upload } from 'src/shared/icons/Upload';
import { makeStyles } from 'tss-react/mui';
import { UploadButton } from './UploadButton';

type PropsType = {
  title: string;
  src: string | null;
  progress: number | null;
  onChangeFile: (value: File | null) => void;
  disabled: boolean;
};

export const PersonalImagePicker = (props: PropsType) => {
  const { title, src, progress, onChangeFile: onSelectFile, disabled } = props;
  const [imageSrc, setImageSrc] = useState<string | null>();
  const { classes } = useStyles();

  useEffect(() => {
    src !== undefined && setImageSrc(src);
  }, [src]);

  const onImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      onSelectFile(file);
      setImageSrc(URL.createObjectURL(file));
    }
  };

  return (
    <div className={classes.column}>
      <Typography sx={{ paddingInlineStart: 2 }} variant="body1" color="neutral.dark">
        {title}
      </Typography>

      <div className={imageSrc ? classes.imageContainerSelected : classes.imageContainer}>
        {imageSrc ? (
          <>
            <div className={classes.row}>
              {progress ? (
                <Typography variant="body3" color="secondary">
                  <div className={classes.circularProgress}>
                    {t`Uploading`}
                    <CircularProgress
                      size="1rem"
                      color="secondary"
                      variant="determinate"
                      value={progress}
                    />
                  </div>
                </Typography>
              ) : (
                <Typography variant="body3" color="success.main">
                  {t`Uploaded`}
                </Typography>
              )}

              {!disabled && src && !progress && (
                <UploadButton
                  name={title}
                  disabled={disabled}
                  onChange={onImageChangeHandler}
                  className={classes.typography}
                >
                  <Upload />
                  {t`Upload new`}
                </UploadButton>
              )}
            </div>

            {imageSrc && (
              <div className={classes.imageBox}>
                <img src={imageSrc} className={classes.image} />
              </div>
            )}
          </>
        ) : (
          <>
            <Upload className={classes.uploadIcon} />
            <Typography variant="body1" color="neutral.lightGrey">
              {t`Upload Picture`}
            </Typography>

            <UploadButton
              name={title}
              disabled={disabled}
              onChange={onImageChangeHandler}
              className={classes.label}
            >
              <Plus /> {t`Upload`}
            </UploadButton>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  circularProgress: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  uploadIcon: {
    width: 64,
    height: 64,
    color: theme.palette.neutral.lightGrey,
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.warning.main,
    cursor: 'pointer',
  },
  label: {
    cursor: 'pointer',
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
    borderRadius: 20,
    color: theme.palette.secondary.main,
    marginTop: 1,
    backgroundColor: theme.palette.neutral.white,
    boxShadow: '0px 8px 24px 0px rgba(16, 137, 255, 0.08)',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  imageContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    backgroundColor: theme.palette.neutral.lightExtraBlue,
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    aspectRatio: 1.39,
  },
  imageContainerSelected: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    backgroundColor: theme.palette.neutral.lightExtraBlue,
    border: `1.75px solid ${theme.palette.secondary.light}`,
    aspectRatio: 1.39,
  },
  imageBox: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
  },

  icon: {
    alignSelf: 'center',
  },
  greenBorder: {
    display: 'flex',
    justifyContent: 'center',
    width: 26.5,
    height: 26.5,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  circle: {
    width: 26.5,
    height: 26.5,
    borderRadius: '50%',
    backgroundColor: theme.palette.neutral.lightGrey,
  },
}));
