import { makeStyles } from 'tss-react/mui';
import { AuthenticationForms } from './components/AuthenticationForms';
import { AuthenticationHeader } from './components/AuthenticationHeader';
import { useAuthStep } from './hooks/useAuthStep';
import { useUser } from 'src/shared/contexts/user/user.context';
import { useEffect, useRef } from 'react';

export const Component = () => {
  const { classes } = useStyles();
  const { user } = useUser();
  const accountStatus = user?.status?.account;
  const { formStep, lastStep, setFormStep } = useAuthStep(accountStatus);
  const contentBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    contentBoxRef.current?.scrollIntoView();
  }, [formStep]);

  return (
    <div>
      <div className={classes.extraGreyBackground} />
      <div className={classes.content} ref={contentBoxRef}>
        <AuthenticationHeader lastStep={lastStep} formStep={formStep} />
        <AuthenticationForms
          formStep={formStep}
          setFormStep={setFormStep}
          disabled={accountStatus === 'active' || accountStatus === 'pending'}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  extraGreyBackground: {
    width: '100%',
    background: theme.palette.neutral.lightExtraBlue,
    height: 92,
    [theme.breakpoints.up('lg')]: {
      height: 96,
      borderTop: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: -90,
    gap: theme.spacing(4),
    padding: theme.spacing(0, 3, 3, 3),
    marginBottom: 96,
    minHeight: 'calc(100dvh - 262px)', //90 navbar + 80 header + 92 greybackground
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
      padding: 0,
      marginTop: -45,
      height: 'calc(100dvh - 262px)', //90 navbar + 80 header + 92 greybackground
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100dvh - 292px)', //112 header + 96 greybackground
    },
  },
}));
