import {
  Divider,
  FormControl,
  ListItemIcon,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography,
  buttonBaseClasses,
  formLabelClasses,
  menuItemClasses,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { TextField } from 'src/shared/components/textfield';
import { ArrowDown } from 'src/shared/icons/ArrowDown';
import { Check } from 'src/shared/icons/Check';
import { components } from 'src/types/shared/auth';
import { makeStyles } from 'tss-react/mui';
import { ContactInformationSchemaType } from '../schema';

type PropsType = {
  label: string;
  placeholder: string;
  options?: components['schemas']['datastruct.CountriesPhoneCodesOutputs'];
  value?: ContactInformationSchemaType['mobile'];
  onChange: (...event: any[]) => void;
  endAdornment: ReactNode;
  disabled: boolean;
};
export const PhoneCodeSelect = (props: PropsType) => {
  const { classes } = useStyles();

  const handleTextFieldOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.onChange({ number: e.target.value, country_code: props.value?.country_code });
  };

  const handleSelectOnChange = (e: SelectChangeEvent<number>) => {
    props.onChange({ number: props.value?.number, country_code: e.target.value });
  };
  const options = props.options?.data?.country_codes ?? [];
  const placeholder = '+98';
  return (
    <TextField
      label={props.label}
      value={props.value?.number}
      startAdornment={
        <FormControl
          className={classes.formControl}
          required
          fullWidth={true}
          disabled={props.disabled}
        >
          <MuiSelect
            IconComponent={ArrowDown}
            renderValue={(value) => {
              const foundedValue = options.find((option) => option.id === value);
              return foundedValue ? (
                <div className={classes.row}>
                  <Typography> {foundedValue.emoji}</Typography>
                  <Typography>{foundedValue.phone_code}+</Typography>
                </div>
              ) : (
                <Typography className={classes.placeholder}>{placeholder}</Typography>
              );
            }}
            value={Number(props.value?.country_code)}
            onChange={handleSelectOnChange}
            displayEmpty={!!placeholder}
            className={classes.select}
          >
            {options.map(({ id: value, name, phone_code, emoji }, index) => [
              <MenuItem classes={{ root: classes.menuItem }} value={value} key={index}>
                <div className={classes.row}>
                  <Typography>
                    {name} {emoji}
                  </Typography>
                  <Typography>{phone_code}+</Typography>
                </div>
                {value === props.value?.country_code && (
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <Check />
                  </ListItemIcon>
                )}
              </MenuItem>,
              index !== options.length - 1 && <Divider classes={{ root: classes.divider }} />,
            ])}
          </MuiSelect>
        </FormControl>
      }
      disabled={props.disabled}
      onChange={handleTextFieldOnChange}
      endAdornment={props.endAdornment}
      inputProps={{ maxLength: 10, className: classes.input }}
      className={classes.textField}
      placeholder={props.placeholder}
      required
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  textField: {
    paddingInlineStart: 0,
  },
  formControl: {
    width: 180,
  },
  input: {
    paddingInlineStart: theme.spacing(1),
  },
  select: {
    borderRadius: 0,
    boxShadow: 'none',
    borderColor: 'rgba(228, 219, 233, 0.25)',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(228, 219, 233, 0.25)',
      boxShadow: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none',
      borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '&& > .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none',
      borderColor: 'transparent',
      borderRight: `1.5px solid ${theme.palette.neutral.lightenDark}`,
    },
    '.MuiSvgIcon-root ': {
      boxShadow: 'none',
      borderColor: 'rgba(228, 219, 233, 0.25)',
    },
  },
  formLabel: {
    color: theme.palette.common.black,
    [`&.${formLabelClasses.focused}`]: {
      color: theme.palette.common.black,
    },
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.secondary.light,
  },
  menuItem: {
    [`&.${buttonBaseClasses.root}.${menuItemClasses.selected}`]: {
      background: 'transparent',
      color: theme.palette.secondary.light,
    },
    justifyContent: 'space-between',
  },
  divider: {
    marginLeft: 8,
    marginRight: 8,
    borderColor: theme.palette.neutral.lightBlue,
  },
  placeholder: {
    color: theme.palette.neutral.lightGrey,
  },
}));
