import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Close } from 'src/shared/icons/Close';
import { makeStyles } from 'tss-react/mui';

type PropsType = {
  disabled: boolean;
  handleDelete: () => void;
  bankName?: string;
  accountText?: string;
};
export const BankCard = (props: PropsType) => {
  const { classes } = useStyles();
  return (
    <div className={classes.card}>
      <Typography variant="body2" color={'neutral.dark'}>
        {`${props.bankName} - `}
        {props.accountText}
      </Typography>
      {!props.disabled && (
        <Typography
          onClick={props.handleDelete}
          variant="body3"
          color={'error.main'}
          className={classes.delete}
        >
          <Close />
          {t`Delete`}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 10,
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.neutral.lightExtraBlue,
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  delete: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
