import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { isAppLocaleFa } from 'src/locales/utils';
import { makeStyles } from 'tss-react/mui';

const MyHeader = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.titleContainer}>
      <Typography variant="h3">{t`Bank information`}</Typography>
      <Typography variant="body2" color={'neutral.darkGrey'}>
        {t`You must at least enter a bank account to get started. Bank information must be your name.`}
      </Typography>
      {!isAppLocaleFa() && (
        <Typography variant="body2" color={'neutral.darkGrey'}>
          {t`TRANSIT numbers are always 5 digits long, so if someone gives you 4 digits (like TD and BMO typically do), you'll need to find that last digit. When in doubt, ask your recipient to check their void cheque or to contact their bank.`}
        </Typography>
      )}
    </div>
  );
};

export const Header = memo(MyHeader);

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
}));
