import 'leaflet/dist/leaflet.css';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';
import { router } from 'src/core/router/router';
import { globalStyles } from 'src/core/theme/GlobalStyles';
import { ThemeProvider } from 'src/core/theme/ThemeProvider';
import { queryClientConfig } from './core/query/queryClientConfig';
import { getAppDirection } from './core/theme/getAppDirection';
import { PageHeader } from './shared/components/PageHeader';
import { ToastContainer } from 'react-toastify';
import { toastConfig } from './core/_config/react-toastify';
import { ConfirmContextProvider } from './shared/contexts/confirm/confirm.context';

export function App() {
  document.body.dir = getAppDirection();

  return (
    <I18nProvider i18n={i18n}>
      <ThemeProvider>
        <PageHeader />
        <CssBaseline />
        <GlobalStyles styles={globalStyles} />
        <QueryClientProvider client={queryClientConfig}>
          <ConfirmContextProvider>
            <ToastContainer {...toastConfig} />
            <RouterProvider router={router} />
          </ConfirmContextProvider>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </ThemeProvider>
    </I18nProvider>
  );
}
