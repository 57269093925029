import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, Typography, alpha } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { authPostKeygen, authQueryKeygen } from 'src/core/query/en/authClient';
import { AutoComplete } from 'src/shared/components/AutoComplete';
import { ErrorCaption } from 'src/shared/components/ErrorCaption';
import { TextField } from 'src/shared/components/textfield';
import { Close } from 'src/shared/icons/Close';
import { setCaptionError } from 'src/shared/utils/error-utils';
import { makeStyles } from 'tss-react/mui';
import { EnVerifyBankCodeSchemaType, enVerifyBankCodeSchema } from './schema';
import { useState } from 'react';
import { Switch } from 'src/shared/components/Switch';

interface PropsType {
  open: boolean;
  onClose: () => void;
  accountNumber?: string;
}

export const BankModal = (props: PropsType) => {
  const { open, onClose } = props;
  const { classes } = useStyles();
  const [textBank, setTextBank] = useState(false);
  const { mutateAsync: mutateAsyncBank, isLoading: isBankLoading } = useMutation(
    authPostKeygen('/api/v1/auth/user/investor/bank'),
  );

  const { data: bankListData } = useQuery({
    ...authQueryKeygen('/api/v1/auth/banks-list', {
      params: { header: {} },
    }),
  });

  const sendCardNumber = (accountNumber: string, cardNumber: string, bankName: string) => () => {
    mutateAsyncBank({
      body: {
        account_number: accountNumber,
        bank_name: bankName,
        card_number: cardNumber,
      },
      params: {},
    }).then((resp) => {
      if (resp.error) {
        setCaptionError(
          {
            // @ts-ignore
            code: resp.error.data.account_number,
            // @ts-ignore
            card_number: resp.error.data.card_number,
            // @ts-ignore
            bank_name: resp.error.data.bank_name,
          },
          setError,
        );
      } else if (resp.data) props.onClose();
    });
  };

  const {
    control,
    formState: { errors, isValid },
    setError,
    reset,
    watch,
  } = useForm<EnVerifyBankCodeSchemaType>({
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
    resolver: zodResolver(enVerifyBankCodeSchema()),
  });

  const labelValueOptions = bankListData?.data?.banks
    ? bankListData.data?.banks.map((obj) => ({
        label: obj.name,
        value: obj.name,
      }))
    : [];

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <div className={classes.title}>
        <Typography variant="h4">{t`Add bank account`}</Typography>
      </div>
      <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
      <div className={classes.form}>
        <Typography variant="caption2" color={'neutral.darkGrey'}>
          {t`Canadian banking account number consists of: Institution Number( 4 digits long) + Transit Number( 5 digits long) + Account Number( 7-12 digits long)`}
        </Typography>
        <Controller
          name={'code'}
          control={control}
          render={({ field }) => (
            <div>
              <TextField
                value={field.value}
                onChange={field.onChange}
                label={t`Account number`}
                inputProps={{ maxLength: 20 }}
                placeholder={t`Enter the account number`}
                required
                fullWidth
              />
              <ErrorCaption caption={errors.code?.message} />
            </div>
          )}
        />
        <Controller
          name={'card_number'}
          control={control}
          render={({ field }) => (
            <div>
              <TextField
                value={field.value}
                onChange={field.onChange}
                label={t`Card number`}
                inputProps={{ maxLength: 16 }}
                placeholder={t`Enter the card number`}
                required
                fullWidth
              />
              <ErrorCaption caption={errors.card_number?.message} />
            </div>
          )}
        />
        {textBank ? (
          <Controller
            name={'bank_name'}
            control={control}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                inputProps={{ maxLength: 16 }}
                placeholder={t`Enter the bank name`}
                label={t`Bank name`}
                fullWidth
                required
                helperText={errors['bank_name']?.message}
                error={!!errors['bank_name']?.message}
              />
            )}
          />
        ) : (
          <Controller
            name={'bank_name'}
            control={control}
            render={({ field }) => {
              let value = labelValueOptions.find((item) => item.value === field.value);
              value = value ? value : { label: '', value: '' };
              return (
                <AutoComplete<string, string, false, undefined>
                  options={labelValueOptions}
                  fullWidth
                  value={value}
                  onChange={(_, value) => {
                    field.onChange(value?.value);
                  }}
                  required
                  placeholder={t`Select bank name`}
                  label={t`Bank name`}
                  helperText={errors['bank_name']?.message}
                  error={!!errors['bank_name']?.message}
                />
              );
            }}
          />
        )}
        <div className={classes.row}>
          <Typography>{t`Type in bank name`}</Typography>
          <Switch
            checked={textBank}
            onChange={(_, checked) => {
              reset({
                bank_name: '',
                card_number: watch('card_number'),
                code: watch('code'),
              });
              setTextBank(checked);
            }}
          />
        </div>
      </div>
      <div className={classes.action}>
        <LoadingButton
          variant="contained"
          fullWidth
          disabled={!isValid}
          onClick={sendCardNumber(watch('code'), watch('card_number'), watch('bank_name'))}
          loading={isBankLoading}
        >
          {t`Add account`}
        </LoadingButton>
      </div>
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    padding: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    borderBottom: `1.5px solid ${theme.palette.neutral.lightBlue}`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    gap: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    border: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    width: 350,
    borderRadius: 16,
    boxShadow: `0px 16px 32px 0px ${alpha(theme.palette.neutral.dark, 0.04)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    },
    color: theme.palette.grey[500],
  },
}));
