import { useQuery } from 'react-query';
import { authQueryKeygen as faAuthQueryKeygen } from 'src/core/query/fa/authClient';

export const useAllAuthInfo = () => {
  return useQuery({
    ...faAuthQueryKeygen('/api/v1/auth/user/investor/info', {
      params: { header: {} },
    }),
    enabled: true,
  });
};
