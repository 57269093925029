import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type PropsType = {
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  nextDisabled: boolean;
  isNextLoading?: boolean;
  submit?: boolean;
};
export const BottomOptions = (props: PropsType) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <div className={classes.footerWrapper}>
        {props.onPreviousClick && (
          <Button onClick={props.onPreviousClick} variant="contained">
            {t`Previous stage`}
          </Button>
        )}
        {/* works as placeholder */}
        {!props.onPreviousClick && <span />}
        {props.onNextClick && (
          <LoadingButton
            onClick={props.onNextClick}
            variant="contained"
            disabled={props.nextDisabled}
            loading={props.isNextLoading}
          >
            {props.submit ? t`Submit` : t`Next stage`}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    right: 0,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.neutral.white,
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2),
    boxShadow: '0px -16px 48px 0px rgba(11, 18, 38, 0.04)',
    borderTop: `1.5px solid ${theme.palette.neutral.lightBlue}`,
    [theme.breakpoints.up('md')]: {
      height: 96,
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100vw - 270px)',
    },
  },
  footerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(2),
      justifyContent: 'flex-end',
    },
  },
}));
