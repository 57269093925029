import createClient from 'openapi-fetch';
import { UseInfiniteQueryOptions, UseQueryOptions } from 'react-query';
import { paths } from 'src/types/fa/wallet';
import { defaultClientConfig } from '../clientConfig';

export const { GET, POST } = createClient<paths>(defaultClientConfig);

export const walletQueryKeygen = <
  URL extends Parameters<typeof GET>[0],
  INIT extends Parameters<typeof GET>[1],
>(
  url: URL,
  init: INIT,
): UseQueryOptions<Awaited<ReturnType<typeof GET<URL>>>['data']> => {
  return {
    queryKey: [url, init],
  };
};

export const walletInfiniteQueryKeygen = <
  URL extends Parameters<typeof GET>[0],
  INIT extends Parameters<typeof GET>[1],
>(
  url: URL,
  init: INIT,
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof GET<URL>>>['data']> => {
  return {
    queryKey: [url, init],
  };
};
