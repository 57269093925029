import { useRef, useState } from 'react';

interface PropsType {
  onClose: () => void;
  verifyOTP: (code: number, nuance: number) => void;
  lsKey: string;
  requestOtpResponse: () => Promise<ResponseType>;
}

type OTPStateType = 'no-code-sent-yet' | 'code-already-sent';

export type ResponseType =
  | { data: { tempCode: number; time: number }; error: undefined }
  | { error: { remainedTime: number }; data: undefined };

export const useOTP = (props: PropsType) => {
  const codeInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [nuance, setNuance] = useState<number | null>(null);
  const [maxTime, setMaxTime] = useState<number | null>(null);
  const [timerCount, setTimerCount] = useState<number>(0);
  const [isLoadingOTP, setIsLoadingOTP] = useState(false);

  const OTPState: OTPStateType = !nuance ? 'no-code-sent-yet' : 'code-already-sent';

  const remainingOTPTime = (maxTime ?? 0) - timerCount;

  const requestOTPCode = async () => {
    setIsLoadingOTP(true);
    const response = await props.requestOtpResponse();
    // console.log('response requestOTPCode', response);
    setIsLoadingOTP(false);

    if (response?.data) {
      setNuance(response.data.tempCode);
      localStorage.setItem(props.lsKey, String(response.data.tempCode));

      setMaxTime(Number(response.data.time));
      if (codeInterval.current) clearInterval(codeInterval.current);
      setTimerCount(0);
      codeInterval.current = setInterval(() => {
        setTimerCount((prevTimerCount) => {
          // console.log('success otp', prevTimerCount, maxTime);
          if (prevTimerCount < Number(response.data.time)) {
            return prevTimerCount + 1;
          } else {
            if (codeInterval.current) clearInterval(codeInterval.current);
            setNuance(null);
            localStorage.setItem(props.lsKey, '');
            return 0;
          }
        });
      }, 1000);
    } else if (response.error) {
      const tmpNuance = localStorage.getItem(props.lsKey);
      const timer = response.error.remainedTime;

      setNuance(Number(tmpNuance));
      setMaxTime(Number(timer));

      if (codeInterval.current) clearInterval(codeInterval.current);
      codeInterval.current = setInterval(() => {
        setTimerCount((prevTimerCount) => {
          // console.log('early otp', prevTimerCount, maxTime);
          if (prevTimerCount < Number(timer)) {
            return prevTimerCount + 1;
          } else {
            if (codeInterval.current) clearInterval(codeInterval.current);
            setNuance(null);
            localStorage.setItem(props.lsKey, '');
            return 0;
          }
        });
      }, 1000);
    }
  };

  const handleVerifyOTPCode = (code: number) => () => {
    if (OTPState === 'code-already-sent' && code && nuance) props.verifyOTP(code, nuance);
  };

  const handleRequestOTPCode = () => {
    requestOTPCode();
  };

  return {
    handleRequestOTPCode,
    handleVerifyOTPCode,
    OTPState,
    remainingOTPTime,
    maxTime,
    isLoadingOTP,
  };
};
