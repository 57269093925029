import { t } from '@lingui/macro';
import { components } from 'src/types/fa/auth';
import { AuthenticationType } from '../../types';

type PropsType = {
  provinceData?: components['schemas']['datastruct.ProvincesInfoListOutputs'];
  cityData?: components['schemas']['datastruct.CitiesInfoListOutputs'];
  authenticationType: AuthenticationType;
  provinceOnChanged: (value: number) => void;
};

type NameType = Exclude<
  keyof Exclude<
    components['schemas']['datastruct.GetInvestorContactInfoOutputs']['data'],
    undefined
  >,
  'country_id' | 'email' | 'mobile'
>;
type SelectValues = Exclude<
  components['schemas']['datastruct.CountriesInfoListOutputs']['data'],
  undefined
>['countries'];

export type FieldType = {
  label: string;
  placeholder: string;
  type: 'text' | 'tel' | 'number' | 'select';
  name: NameType;
  disable?: boolean;
  values?: SelectValues;
  confirmed?: boolean;
  onChange?: (value: number) => void;
  optional?: boolean;
};

export const fillInputs = (props: PropsType) => {
  const dataInputs: FieldType[] = [
    {
      label: t`Province`,
      placeholder: t`Select the desired province`,
      type: 'select',
      name: 'province_id',
      values: props.provinceData?.data?.provinces,
      disable: !props.provinceData?.data?.provinces,
      onChange: props.provinceOnChanged,
    },
    {
      label: t`City`,
      placeholder: t`Select the desired city`,
      type: 'select',
      name: 'city_id',
      values: props.cityData?.data?.cities,
      disable: !props.cityData?.data?.cities,
    },

    {
      label: t`Home phone`,
      placeholder: `۰۲۱۱۲۳۴۵۶۷`,
      type: 'tel',
      name: 'home_phone',
      optional: true,
    },
    {
      label: t`Zip code`,
      placeholder: 'کد پستی خود را وارد کنید',
      type: 'tel',
      name: 'postal_code',
    },
  ];
  const addressInput: FieldType = {
    label: t`Address`,
    placeholder:
      props.authenticationType === 'instant'
        ? `آدرس بر اساس کد پستی پر خواهد شد`
        : ' لطفاً آدرس پستی صحیح مطابق با کدپستی را درج کنید.',
    type: 'text',
    name: 'address',
    optional: props.authenticationType === 'instant',
    disable: props.authenticationType === 'instant',
  };

  return {
    dataInputs,
    addressInput,
  };
};
