import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { TssCacheProvider } from 'tss-react';
import { ltrTheme, rtlTheme } from './theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { getAppDirection } from './getAppDirection';

const isRtl = getAppDirection() === 'rtl';

const cache = createCache({
  key: isRtl ? 'mui-rtl' : 'mui-ltr',
  prepend: true,
  stylisPlugins: isRtl ? [rtlPlugin] : [],
});

const tssCache = createCache({
  key: isRtl ? 'tss-rtl' : 'tss-ltr',
  stylisPlugins: isRtl ? [rtlPlugin] : [],
});

export function ThemeProvider(props: React.PropsWithChildren) {
  return (
    <CacheProvider value={cache}>
      <TssCacheProvider value={tssCache}>
        <MuiThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>{props.children}</MuiThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
}
