import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorCaption } from 'src/shared/components/ErrorCaption';
import { Select } from 'src/shared/components/select';
import { TextField } from 'src/shared/components/textfield';
import { setCaptionError } from 'src/shared/utils/error-utils';
import { makeStyles } from 'tss-react/mui';
import { BottomOptions } from '../../components/BottomOptions';
import { PersonalInformationSchemaType, personalInformationSchema } from '../../schema';
import { usePersonalInfo } from './usePersonalInfo';
import { dataInputs } from './utils';
import { DatePicker } from 'src/shared/components/datePicker/DatePicker';

type PropsType = {
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
};
export const PersonalInfoStage = (props: PropsType) => {
  const { classes } = useStyles();

  const { isLoadingPersonalInfoSubmit, setPersonalInfoDefaultValues, submitPersonalInfo } =
    usePersonalInfo();

  const {
    control,
    formState: { errors, isValid },
    setError,
    watch,
  } = useForm<PersonalInformationSchemaType>({
    defaultValues: setPersonalInfoDefaultValues,
    mode: 'all',
    resolver: zodResolver(personalInformationSchema()),
  });

  const handleGoBackToUploadDocuments = () => {
    props.setFormStep((step) => step - 1);
  };
  const handleGoToContactInfo = () => {
    if (props.disabled) {
      props.setFormStep((step) => step + 1);
      return;
    }
    const res = watch();
    submitPersonalInfo({
      body: {
        birth_date: res.birth_date.toString(),
        first_name: res.first_name,
        gender: res.gender,
        last_name: res.last_name,
        national_id: res.national_id,
      },
      params: {},
    }).then((resp) => {
      if (resp.error) {
        setCaptionError((resp as any).error.data, setError);
      } else if (resp.data) {
        props.setFormStep((step) => step + 1);
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <Typography variant="h3">{t`Personal information`}</Typography>
          <Typography variant="body2" color={'neutral.darkGrey'}>
            {t`Personal information is confirmed if it matches with the national card. This case will be investigated by the admin.`}
          </Typography>
          <Typography variant="body2" color={'neutral.darkGrey'}>
            {t`Please fill in the "ID No." with either your Passport Number or Certificate Number.`}
          </Typography>
        </div>
        <Grid container spacing={3}>
          {dataInputs.map((dataInput) => (
            <Grid key={dataInput.label} item xs={12} sm={6}>
              {dataInput.type === 'text' ? (
                <Controller
                  name={dataInput.name}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <TextField
                        value={field.value}
                        onChange={field.onChange}
                        label={dataInput.label}
                        placeholder={dataInput.placeholder}
                        inputProps={
                          dataInput.name === 'national_id' ? { maxLength: 12 } : undefined
                        }
                        required
                        fullWidth
                        disabled={props.disabled}
                      />
                      <ErrorCaption caption={errors[dataInput.name]?.message} />
                    </div>
                  )}
                />
              ) : dataInput.type === 'select' ? (
                <Controller
                  name={dataInput.name}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Select
                        options={[
                          {
                            value: 'male',
                            label: t`Male`,
                          },
                          {
                            value: 'female',
                            label: t`Female`,
                          },
                        ]}
                        value={(field.value as string) ?? 'male'}
                        onChange={field.onChange}
                        fullWidth
                        required
                        placeholder={dataInput.placeholder}
                        label={dataInput.label}
                        disabled={props.disabled}
                      />
                      <ErrorCaption caption={errors[dataInput.name]?.message} />
                    </div>
                  )}
                />
              ) : (
                <Controller
                  name={dataInput.name}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <DatePicker
                        label={dataInput.label}
                        value={field.value}
                        onChange={field.onChange}
                        className={classes.datePicker}
                        maxDate={new Date()}
                        slotProps={{
                          textField: { color: 'secondary', required: true },
                          desktopPaper: {
                            sx: { boxShadow: '0px -16px 48px 0px rgba(11, 18, 38, 0.04)' },
                          },
                        }}
                        disabled={props.disabled}
                      />
                      <ErrorCaption caption={errors[dataInput.name]?.message} />
                    </div>
                  )}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
      <BottomOptions
        onPreviousClick={handleGoBackToUploadDocuments}
        onNextClick={handleGoToContactInfo}
        nextDisabled={!isValid}
        isNextLoading={isLoadingPersonalInfoSubmit}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 110,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 728,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  datePicker: {
    width: '100%',
  },
}));
